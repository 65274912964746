import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import AsideNav from "../../components/asideNav/asideNav";
import HeaderDefault from "../../layout/headerDefault/headerDefault";
import Bradscubs from "../../components/breadcubs/breadcubs";
import Partners from "../../components/partners";
import FooterDefault from "../../layout/footerDefault/footerDefault";
import { ProductButtonsDefault } from "../../components/ProductButtonsDefault";
import { generateBradscubs } from "../../utils/generateBradscubs";
import { BradscubItem } from "../../models/interface";
import { ROUTES } from "../../models/constant";

const ProductCart = React.lazy(() => import("../../components/productCard"));

const SearchResultsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchResults = location.state?.searchResults;

  useEffect(() => {
    if (!searchResults) {
      navigate("/main");
    }
  }, [navigate, searchResults]);

  if (searchResults === undefined) {
    return null;
  }
  const bradscubs: BradscubItem[] = [
    {
      id: 1,
      link: ROUTES.main,
      name: "Главная страница",
    },
    {
      id: 2,
      name: "Результаты поиска",
      active: true,
    },
  ];

  return (
    <>
      <div className="wrapper__inner">
        <Helmet>
          <meta name="description" content="Описание Каталог товаров  " />
          <meta
            name="keywords"
            content="ключевое слово Каталог товаров 1, ключевое слово Каталог товаров 2"
          />
        </Helmet>
        <div className="text_page fav catalog">
          <div className=" d-flex flex-nowrap">
            <AsideNav />
            <div style={{ width: "100%" }}>
              <HeaderDefault />
              <div className="product_wrapper">
                <div className="text_page_top">
                  <h1>Результаты поиска</h1>
                  <Bradscubs bradscubs={bradscubs} />
                </div>
                <div className="catalog_list row">
                  {searchResults.length > 0 ? (
                    searchResults.map((product: any) => (
                      <React.Suspense
                        key={product.id}
                        fallback={<div>Загрузка...</div>}
                      >
                        <ProductCart product={product}>
                          <ProductButtonsDefault product={product} />
                        </ProductCart>
                      </React.Suspense>
                    ))
                  ) : (
                    <p>По вашему запросу ничего не найдено.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partners />
      <FooterDefault />
    </>
  );
};

export default SearchResultsPage;
