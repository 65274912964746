export const categoryIcons = [
  `<svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.7368 34.055H0V29.6501H10.7368V34.055ZM1.10121 32.9538H9.63563V30.7514H1.10121V32.9538Z"
            fill="#FF781F" />
        <path
            d="M22.024 34.055H11.2871V29.6501H22.024V34.055ZM12.3883 32.9538H20.9227V30.7514H12.3883V32.9538Z"
            fill="#FF781F" />
        <path
            d="M33.312 34.055H22.5752V29.6501H33.312V34.055ZM23.6764 32.9538H32.2108V30.7514H23.6764V32.9538Z"
            fill="#FF781F" />
        <path
            d="M16.2427 29.0997H5.50586V24.6948H16.2427V29.0997ZM6.60707 27.9985H15.1415V25.796H6.60707V27.9985Z"
            fill="#FF781F" />
        <path
            d="M27.5308 29.0997H16.7939V24.6948H27.5308V29.0997ZM17.8952 27.9985H26.4296V25.796H17.8952V27.9985Z"
            fill="#FF781F" />
        <path
            d="M33.3118 29.0997H28.0811V24.6948H33.3118V25.796H29.1823V27.9985H33.3118V29.0997Z"
            fill="#FF781F" />
        <path d="M4.95547 29.0997H0V27.9985H3.85425V25.796H0V24.6948H4.95547V29.0997Z"
            fill="#FF781F" />
        <path
            d="M10.7368 24.1441H0V19.7393H10.7368V24.1441ZM1.10121 23.0429H9.63563V20.8405H1.10121V23.0429Z"
            fill="#FF781F" />
        <path
            d="M22.024 24.1441H11.2871V19.7393H22.024V24.1441ZM12.3883 23.0429H20.9227V20.8405H12.3883V23.0429Z"
            fill="#FF781F" />
        <path
            d="M33.312 24.1441H22.5752V19.7393H33.312V24.1441ZM23.6764 23.0429H32.2108V20.8405H23.6764V23.0429Z"
            fill="#FF781F" />
        <path
            d="M16.2427 19.1888H5.50586V14.7839H16.2427V19.1888ZM6.60707 18.0876H15.1415V15.8852H6.60707V18.0876Z"
            fill="#FF781F" />
        <path
            d="M27.5308 19.1888H16.7939V14.7839H27.5308V19.1888ZM17.8952 18.0876H26.4296V15.8852H17.8952V18.0876Z"
            fill="#FF781F" />
        <path
            d="M33.3118 19.1888H28.0811V14.7839H33.3118V15.8852H29.1823V18.0876H33.3118V19.1888Z"
            fill="#FF781F" />
        <path d="M4.95547 19.1888H0V18.0876H3.85425V15.8852H0V14.7839H4.95547V19.1888Z"
            fill="#FF781F" />
        <path
            d="M10.7368 14.2332H0V9.82837H10.7368V14.2332ZM1.10121 13.132H9.63563V10.9296H1.10121V13.132Z"
            fill="#FF781F" />
        <path
            d="M22.024 14.2332H11.2871V9.82837H22.024V14.2332ZM12.3883 13.132H20.9227V10.9296H12.3883V13.132Z"
            fill="#FF781F" />
        <g opacity="0.3">
            <path d="M33.3112 13.1321H23.125V14.2333H33.3112V13.1321Z" fill="#FF781F" />
        </g>
        <path d="M4.95547 9.27766H0V8.17645H3.85425V5.97402H0V4.8728H4.95547V9.27766Z"
            fill="#FF781F" />
        <path
            d="M23.9243 7.79109L22.4102 3.66154L32.4863 0L34.0004 4.12955L23.9243 7.79109ZM23.8417 4.29474L24.5851 6.35951L32.5964 3.4413L31.8531 1.37652L23.8417 4.29474Z"
            fill="#FF781F" />
    </svg>`,
  `<svg width="33" height="39" viewBox="0 0 33 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M33.0002 18.9279V10.7011H31.4538V6.80416V6.1856V0H30.2167V6.1856H3.4021V10.7011H2.72168V11.9382H3.4021V16.4537H9.64955V38.3507H10.8867V16.4537H31.4538V11.9382H31.7631V17.8764L16.2991 20.4434V26.7836H14.5052V38.3507H19.3609V26.8146H17.5362V21.4949L33.0002 18.9279ZM30.2167 15.2166H4.63921V7.42272H30.2167V15.2166ZM18.0929 37.1136H15.7114V28.0517H18.0929V37.1136Z"
            fill="#FF781F" />
        <g opacity="0.3">
            <path d="M6.55646 21.6497H5.31934V22.8868H6.55646V21.6497Z" fill="#FF781F" />
        </g>
        <g opacity="0.3">
            <path d="M26.9695 0H25.7324V1.23712H26.9695V0Z" fill="#FF781F" />
        </g>
        <g opacity="0.3">
            <path d="M20.783 3.09277H19.5459V4.32989H20.783V3.09277Z" fill="#FF781F" />
        </g>
        <g opacity="0.3">
            <path d="M8.41192 0H7.1748V1.23712H8.41192V0Z" fill="#FF781F" />
        </g>
        <g opacity="0.3">
            <path d="M1.29864 3.09277H0.0615234V4.32989H1.29864V3.09277Z" fill="#FF781F" />
        </g>
        <g opacity="0.3">
            <path d="M6.55646 32.165H5.31934V38.3506H6.55646V32.165Z" fill="#FF781F" />
        </g>
        <g opacity="0.3">
            <path d="M1.23712 34.3918H0V35.629H1.23712V34.3918Z" fill="#FF781F" />
        </g>
        <g opacity="0.3">
            <path d="M1.23712 18.928H0V25.1136H1.23712V18.928Z" fill="#FF781F" />
        </g>
    </svg>`,
  `<svg width="33" height="22" viewBox="0 0 33 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.65574 7.81728L12.4426 7.84433V1.08203H8.65574V1.10908C7.11393 1.24433 5.89672 2.43449 5.70738 3.94924H0V5.03121H5.70738C5.89672 6.51892 7.11393 7.70908 8.65574 7.81728ZM9.73771 2.164H11.3607V6.76236H9.73771V2.164ZM6.7623 4.32793C6.7623 3.21892 7.60082 2.32629 8.65574 2.19105V6.76236C7.60082 6.62711 6.7623 5.70744 6.7623 4.62547V4.32793Z"
            fill="#FF781F" />
        <path d="M29.2127 1.62305H26.5078V2.70501H29.2127V1.62305Z" fill="#FF781F" />
        <path d="M29.2127 3.24585H26.5078V4.32782H29.2127V3.24585Z" fill="#FF781F" />
        <path
            d="M10.9551 10.7115V11.7934H11.6313V20.2328H10.9551V21.3148H16.0944V20.2328H15.4182V11.7934H16.0944V10.7115H14.0657V8.92623H20.828V11.6311H22.1805H22.7215C23.1813 11.6311 23.5329 11.9828 23.5329 12.4426V21.3148H27.0493C28.1043 21.3148 28.9428 20.4762 28.9428 19.4213V8.89918C31.2149 8.68279 33.0002 6.78934 33.0002 4.46311C33.0002 2.00164 30.9985 0 28.537 0H12.9838V8.38525V8.92623V10.7115H10.9551ZM21.91 10.5492V8.92623H22.1805V10.5492H21.91ZM14.0657 1.08197H28.537C30.4034 1.08197 31.9182 2.59672 31.9182 4.46311C31.9182 6.32951 30.4034 7.84426 28.537 7.84426H27.8608V19.4213C27.8608 19.8811 27.5092 20.2328 27.0493 20.2328H24.6149V12.4426C24.6149 11.577 24.0469 10.8738 23.2625 10.6303V8.92623H24.6149V7.84426H23.2625H22.7215H14.0657V1.08197ZM14.3362 20.2328H12.7133V11.7934H14.3362V20.2328Z"
            fill="#FF781F" />
    </svg>`,
  `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.2344 16.815L15.2227 15.8032L5.88923 25.1367L6.90098 26.1484L16.2344 16.815Z"
            fill="#FF781F" />
        <path d="M4.25655 28.4071C4.5924 28.4071 4.86466 28.1349 4.86466 27.799C4.86466 27.4632 4.5924 27.1909 4.25655 27.1909C3.9207 27.1909 3.64844 27.4632 3.64844 27.799C3.64844 28.1349 3.9207 28.4071 4.25655 28.4071Z" fill="#FF781F" />
        <path d="M25.8265 6.98005C25.4687 6.87274 25.1468 6.58657 25.0395 6.22886C24.9322 5.87115 25.0395 5.47766 25.2899 5.19149C25.5403 4.90532 25.9338 4.79801 26.3272 4.90532C26.685 5.01264 27.0069 5.29881 27.1142 5.65652C27.2215 6.01423 27.1142 6.40771 26.8638 6.69388C26.5776 6.94428 26.1842 7.08737 25.8265 6.98005Z"
            fill="#FF781F" />
        <path d="M30.978 6.98025L30.9423 6.80139L30.6561 6.51522L29.4757 2.4373L25.5766 1.47148L25.2189 1.11377L25.04 1.078C22.1425 0.291028 19.102 1.14954 16.9915 3.22427C16.9915 3.22427 16.9915 3.22427 16.9557 3.26004C15.2387 4.97706 14.416 7.30219 14.5233 9.59155V9.87772C14.4875 11.5232 12.842 14.2776 11.4112 15.7084L1.7887 25.3309C1.14482 26.0106 0.787109 26.8691 0.787109 27.7991C0.787109 28.7292 1.14482 29.6235 1.7887 30.2673C2.46836 30.947 3.36264 31.2689 4.25692 31.2689C5.1512 31.2689 6.00971 30.947 6.68937 30.2673L16.3118 20.6449C17.7427 19.1782 20.4971 17.5685 22.1425 17.5328C22.3929 17.5685 22.6433 17.5685 22.8937 17.5685C25.1473 17.5685 27.2221 16.71 28.8318 15.1003C30.9423 12.9898 31.765 9.87772 30.978 6.98025ZM25.2189 2.83079L28.2952 3.61775L29.1895 6.72985L26.9359 9.05498L23.788 8.26802L22.8937 5.15592L23.788 4.26164L25.2189 2.83079ZM25.7912 10.2354L25.1116 10.9151C23.0726 10.6289 21.4271 8.98344 21.1409 6.94448L21.7848 6.3006L22.6791 9.44847L25.7912 10.2354ZM15.3102 19.6075L5.68777 29.23C4.9008 30.0169 3.57727 30.0169 2.7903 29.23C2.39682 28.8723 2.18219 28.3357 2.18219 27.7991C2.18219 27.2625 2.39682 26.7617 2.7903 26.3683L12.4128 16.71C13.307 15.8157 14.4875 14.206 15.2387 12.5963C15.6322 13.5264 16.2045 14.3849 16.9557 15.1003C17.6711 15.8157 18.5296 16.3881 19.4239 16.8173C17.7785 17.5685 16.2045 18.7132 15.3102 19.6075ZM27.7944 14.063C26.5066 15.315 24.8254 16.0304 23.0368 16.0661L22.536 16.1019C20.819 16.0304 19.1735 15.315 17.9931 14.063C16.7053 12.7752 16.0614 11.1655 15.9541 9.48424L15.9899 8.98344C16.0257 7.40951 16.5622 5.90711 17.5638 4.72666C17.7069 4.54781 17.85 4.40472 17.9931 4.22587C19.5312 2.6877 21.6417 1.97228 23.7523 2.25845L22.7864 3.26004L19.6743 6.40791L19.7101 6.72985C19.9247 9.73464 22.3572 12.1671 25.362 12.3817L25.6839 12.4175L27.4367 10.6647L27.6871 10.3785L29.7618 8.30379C30.048 10.3785 29.3326 12.5606 27.7944 14.063Z"
            fill="#FF781F" />
    </svg>`,
  `<svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
    d="M31 4.11618V0H22.5104V4.11618H23.6681V17.751H22.5104V21.8672H23.6166C23.2564 24.0797 21.327 25.7261 19.0373 25.7261C16.7477 25.7261 14.844 24.0539 14.4838 21.8672H15.5643V17.751H14.3809C13.995 12.3228 9.57013 7.9751 4.11618 7.71784V6.6888H0V15.1784H4.11618V13.9178C6.17427 14.1494 7.82075 15.7187 8.15519 17.751H7.07469V21.8672H8.25809C8.64398 27.4755 13.3261 31.9004 19.0373 31.9004C24.7743 31.9004 29.4307 27.527 29.8166 21.8672H31V17.751H29.8423V4.11618H31ZM3.08714 14.1494H1.02905V7.71784H3.08714V14.1494ZM4.11618 12.8888V8.74689C9.00415 9.00415 12.966 12.8631 13.3519 17.7253H9.20996C8.84979 15.1527 6.74025 13.1203 4.11618 12.8888ZM8.10373 20.8382V18.7801H14.5353V20.8382H8.10373ZM19.0373 30.8714C13.8921 30.8714 9.67303 26.8838 9.28714 21.8672H13.429C13.8149 24.6199 16.1817 26.7552 19.0373 26.7552C21.8672 26.7552 24.2598 24.6456 24.6456 21.8672H28.7876C28.4017 26.9353 24.1826 30.8714 19.0373 30.8714ZM29.971 18.7801V20.8382H23.5394V18.7801H29.971ZM24.6971 17.751V4.11618H28.8133V17.751H24.6971ZM23.5394 3.08714V1.02905H29.971V3.08714H23.5394Z"
    fill="#FF781F" />
                            <path opacity="0.3"
    d="M22.5871 29.4564C24.7223 28.556 26.446 26.7809 27.2949 24.6457L26.3431 24.2598C25.597 26.1635 24.0792 27.7071 22.2012 28.5046L22.5871 29.4564Z"
    fill="#FF781F" />
                        </svg>`,
  `<svg width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
    d="M29.75 36.4286H0V37.6429H29.75C32.0875 37.6429 34 35.7304 34 33.3929C34 31.0554 32.0875 29.1429 29.75 29.1429H23.375V28.5357H31.5714V27.0179C33.1196 25.7429 34 23.8911 34 21.8571V8.19643C34 4.70536 31.3286 1.85179 27.9286 1.54821V0H5.46429V4.25H27.9286V2.7625C30.6607 3.06607 32.7857 5.37321 32.7857 8.19643V21.8571C32.7857 23.1321 32.3607 24.3464 31.5714 25.2875V8.80357H1.82143V28.5357H10.0179V29.1429H7.58929V30.3571H10.0179V32.1786H23.375V30.3571H29.75C31.4196 30.3571 32.7857 31.7232 32.7857 33.3929C32.7857 35.0625 31.4196 36.4286 29.75 36.4286ZM26.7143 3.03571H6.67857V1.21429H26.7143V3.03571ZM3.03571 10.0179H30.3571V27.3214H3.03571V10.0179ZM11.2321 30.9643V28.5357H22.1607V30.9643H11.2321Z"
    fill="#FF781F" />
                            <path
    d="M3.94629 26.4106H29.4463V10.9285H3.94629V26.4106ZM16.0891 25.1963H11.232V12.1428H16.0891V25.1963ZM17.3034 12.1428H22.1606V25.1963H17.3034V12.1428ZM28.232 25.1963H23.3749V12.1428H28.232V25.1963ZM5.16057 12.1428H10.0177V25.1963H5.16057V12.1428Z"
    fill="#FF781F" />
                        </svg>`,
  `<svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
    d="M2.8972 35.3458H0V12.1683C0 6.57667 4.5486 2.02808 10.1402 2.02808H31V4.92527H10.1402C6.14206 4.92527 2.8972 8.17013 2.8972 12.1683V35.3458ZM1.15888 34.187H1.73832V12.1683C1.73832 7.53275 5.50467 3.76639 10.1402 3.76639H29.8411V3.18695H10.1402C5.18598 3.18695 1.15888 7.21406 1.15888 12.1683V34.187Z"
    fill="#FF781F" />
                            <path
    d="M12.4001 35.3457H11.2412V6.37374C11.2412 5.64944 11.3571 4.92514 11.5889 4.25879L12.6898 4.63542C12.487 5.18589 12.4001 5.76533 12.4001 6.37374V35.3457Z"
    fill="#FF781F" />
                            <path
    d="M21.5552 2.92617C20.5701 1.79626 19.1505 1.15888 17.644 1.15888C16.1664 1.15888 14.7468 1.79626 13.7617 2.8972L12.8926 2.11495C14.1094 0.782243 15.8477 0 17.644 0C19.4692 0 21.2365 0.782243 22.4244 2.1729L21.5552 2.92617Z"
    fill="#FF781F" />
                            <path
    d="M22.888 10.5168L22.8591 6.37382C22.8591 5.79438 22.7432 5.21494 22.5693 4.66448L23.6703 4.28784C23.902 4.9542 24.0179 5.6785 24.0469 6.4028V10.5168H22.888Z"
    fill="#FF781F" />
                            <path d="M24.0466 15.8477H22.8877V23.0327H24.0466V15.8477Z" fill="#FF781F" />
                            <path
    d="M26.6541 16.4272H20.1934V13.1824C20.1934 11.3861 21.642 9.9375 23.4382 9.9375C25.2345 9.9375 26.6831 11.3861 26.6831 13.1824V16.4272H26.6541ZM21.3522 15.2683H25.4952V13.1824C25.4952 12.0235 24.5681 11.0964 23.4092 11.0964C22.2504 11.0964 21.3233 12.0235 21.3233 13.1824V15.2683H21.3522Z"
    fill="#FF781F" />
                            <path
    d="M23.4673 31.5796C23.3225 31.5796 23.1486 31.5796 23.0038 31.5506C19.4112 31.3478 16.514 28.4796 16.2533 24.9161C16.0795 22.6562 16.9486 20.4833 18.658 18.9768C19.643 18.1076 20.1935 16.8329 20.1935 15.5002H20.7729V15.2974H26.0748V15.4422H26.6542C26.6542 16.7749 27.2626 18.0787 28.2767 19.0058C29.8122 20.3385 30.7103 22.3086 30.7103 24.3366C30.7103 26.2777 29.957 28.074 28.5953 29.4357C27.2047 30.8263 25.4084 31.5796 23.4673 31.5796ZM21.2654 16.4273C21.0626 17.731 20.3963 18.9478 19.4112 19.846C17.9916 21.1207 17.2673 22.946 17.4122 24.8291C17.6439 27.8132 20.0776 30.2179 23.0617 30.4207C24.829 30.5366 26.5094 29.8992 27.7552 28.6534C28.914 27.4946 29.5225 25.988 29.5225 24.3656C29.5225 22.6562 28.7982 21.0048 27.4944 19.846C26.4804 18.9189 25.814 17.731 25.5823 16.4273H21.2654Z"
    fill="#FF781F" />
                            <path d="M25.8139 22.4534H21.1494V23.6122H25.8139V22.4534Z" fill="#FF781F" />
                        </svg>`,
  `<svg width="44" height="36" viewBox="0 0 44 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
    d="M19.8658 6.99536L4.29102 15.8897H8.92457V27.3061H11.4702V18.1505H18.0747V27.3061H30.807V15.8897H35.4411L19.8658 6.99536ZM24.2595 22.7805H19.9343V18.2539H24.2595V22.7805ZM29.0734 22.7805H24.7477V18.2539H29.0734V22.7805Z"
    fill="#FF781F" />
                            <path
    d="M32.6866 28.6099H7.04561V29.9007H5.39648V30.3809H7.0761V30.1406H8.09556V30.3809H9.77467V30.1406H10.7941V30.3809H12.4727V30.1406H13.4927V30.3809H15.1713V30.1406H16.1908V30.3809H17.8699V30.1406H18.8894V30.3809H20.569V30.1406H21.5879V30.3809H23.2675V30.1406H24.2865V30.3809H25.9656V30.1406H26.9856V30.3809H28.6642V30.1406H29.6842V30.3809H31.3628V30.1406H32.3817V30.3809H34.0618V29.9007H32.6866V28.6099Z"
    fill="#FF781F" />
                            <path d="M17.8695 4.28784H16.1904V4.76809H17.8695V4.28784Z" fill="#FF781F" />
                            <path d="M12.4731 4.28784H10.7939V4.76809H12.4731V4.28784Z" fill="#FF781F" />
                            <path d="M20.5693 4.28784H18.8896V4.76809H20.5693V4.28784Z" fill="#FF781F" />
                            <path d="M1.67911 4.28784H0V4.76809H1.67911V4.28784Z" fill="#FF781F" />
                            <path d="M15.1723 4.28784H13.4932V4.76809H15.1723V4.28784Z" fill="#FF781F" />
                            <path d="M34.0614 4.28784H32.3818V4.76809H34.0614V4.28784Z" fill="#FF781F" />
                            <path d="M42.1571 4.28784H40.4785V4.76809H42.1571V4.28784Z" fill="#FF781F" />
                            <path d="M23.2675 4.28784H21.5879V4.76809H23.2675V4.28784Z" fill="#FF781F" />
                            <path d="M31.3632 4.28784H29.6846V4.76809H31.3632V4.28784Z" fill="#FF781F" />
                            <path d="M28.664 4.28784H26.9854V4.76809H28.664V4.28784Z" fill="#FF781F" />
                            <path d="M25.9657 4.28784H24.2871V4.76809H25.9657V4.28784Z" fill="#FF781F" />
                            <path d="M7.0756 4.28784H5.39648V4.76809H7.0756V4.28784Z" fill="#FF781F" />
                            <path d="M1.67911 29.9006H0V30.3809H1.67911V29.9006Z" fill="#FF781F" />
                            <path d="M42.1571 29.9006H40.4785V30.3809H42.1571V29.9006Z" fill="#FF781F" />
                            <path d="M4.37736 29.9006H2.69824V30.3809H4.05103V31.9216H4.53077V30.2425H4.37736V29.9006Z"
    fill="#FF781F" />
                            <path d="M39.5525 25.8765H39.0732V27.5556H39.5525V25.8765Z" fill="#FF781F" />
                            <path d="M39.5525 23.1772H39.0732V24.8569H39.5525V23.1772Z" fill="#FF781F" />
                            <path d="M39.0726 29.9008H37.7793V30.381H39.4584V30.1067H39.5519V28.5745H39.0726V29.9008Z"
    fill="#FF781F" />
                            <path d="M39.5525 17.7808H39.0732V19.4604H39.5525V17.7808Z" fill="#FF781F" />
                            <path d="M39.5525 20.4792H39.0732V22.1584H39.5525V20.4792Z" fill="#FF781F" />
                            <path d="M39.5525 9.68433H39.0732V11.3639H39.5525V9.68433Z" fill="#FF781F" />
                            <path d="M39.5525 6.98633H39.0732V8.66544H39.5525V6.98633Z" fill="#FF781F" />
                            <path d="M39.0726 5.96745H39.5519V4.28784H39.4584H39.0726H37.7793V4.76809H39.0726V5.96745Z"
    fill="#FF781F" />
                            <path d="M39.5525 12.3835H39.0732V14.0627H39.5525V12.3835Z" fill="#FF781F" />
                            <path
    d="M9.09967 6.63602H9.57892V4.768H9.77482V4.28776H9.57892V0.744629H9.09967V4.28776H8.0957V4.768H9.09967V6.63602Z"
    fill="#FF781F" />
                            <path
    d="M39.5523 15.7532V15.0815H39.0731V15.7532H37.2051V16.2334H39.0731V16.7617H39.5523V16.2334H43.6547V15.7532H39.5523Z"
    fill="#FF781F" />
                            <path d="M35.3562 8.0957H34.877V9.77482H35.3562V8.0957Z" fill="#FF781F" />
                            <path d="M35.3562 26.9858H34.877V28.6655H35.3562V26.9858Z" fill="#FF781F" />
                            <path d="M35.3562 21.5886H34.877V23.2677H35.3562V21.5886Z" fill="#FF781F" />
                            <path d="M35.3562 16.1914H34.877V17.8705H35.3562V16.1914Z" fill="#FF781F" />
                            <path d="M35.3562 18.8896H34.877V20.5693H35.3562V18.8896Z" fill="#FF781F" />
                            <path d="M35.3562 2.69849H34.877V4.3776H35.0808V4.76789H36.7605V4.28765H35.3562V2.69849Z"
    fill="#FF781F" />
                            <path d="M35.3562 24.2866H34.877V25.9662H35.3562V24.2866Z" fill="#FF781F" />
                            <path d="M35.3562 32.3823H34.877V33.7046H35.3562V32.3823Z" fill="#FF781F" />
                            <path d="M35.3562 5.39673H34.877V7.07634H35.3562V5.39673Z" fill="#FF781F" />
                            <path d="M35.3562 13.4929H34.877V15.1725H35.3562V13.4929Z" fill="#FF781F" />
                            <path d="M35.3562 29.6843H34.877V31.3634H35.3562V30.381H36.7605V29.9007H35.3562V29.6843Z"
    fill="#FF781F" />
                            <path d="M35.3562 10.7939H34.877V12.4736H35.3562V10.7939Z" fill="#FF781F" />
                            <path d="M35.3562 0H34.877V1.67961H35.3562V0Z" fill="#FF781F" />
                            <path d="M4.53053 11.3521H4.05078V13.0317H4.53053V11.3521Z" fill="#FF781F" />
                            <path d="M4.53053 0.558105H4.05078V2.23772H4.53053V0.558105Z" fill="#FF781F" />
                            <path d="M4.53053 19.4482H4.05078V21.1279H4.53053V19.4482Z" fill="#FF781F" />
                            <path d="M4.53053 8.65381H4.05078V10.3329H4.53053V8.65381Z" fill="#FF781F" />
                            <path d="M4.53053 22.1467H4.05078V23.8258H4.53053V22.1467Z" fill="#FF781F" />
                            <path d="M4.53053 24.845H4.05078V26.5246H4.53053V24.845Z" fill="#FF781F" />
                            <path d="M4.53053 32.9412H4.05078V34.2635H4.53053V32.9412Z" fill="#FF781F" />
                            <path d="M4.05103 4.93595H4.53077V3.25684H4.05103V4.28779H2.69824V4.76804H4.05103V4.93595Z"
    fill="#FF781F" />
                            <path d="M4.53053 27.5439H4.05078V29.2236H4.53053V27.5439Z" fill="#FF781F" />
                            <path d="M4.53053 5.95532H4.05078V7.63494H4.53053V5.95532Z" fill="#FF781F" />
                            <path d="M4.53053 16.7498H4.05078V18.4289H4.53053V16.7498Z" fill="#FF781F" />
                            <path d="M4.53053 14.051H4.05078V15.7306H4.53053V14.051Z" fill="#FF781F" />
                            <path
    d="M18.0356 34.2134H11.2551V32.9456H10.7754V34.2134V34.6936V35.962H11.2551V34.6936H18.0356V35.962H18.5153V32.9456H18.0356V34.2134Z"
    fill="#FF781F" />
                        </svg>`,
];

export const categoryIconsWhites = [
  `<svg width="34.000000" height="35.000000" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <desc>
          Created with Pixso.
  </desc>
  <defs/>
  <path id="Vector" d="M10.73 34.05L0 34.05L0 29.65L10.73 29.65L10.73 34.05ZM1.1 32.95L9.63 32.95L9.63 30.75L1.1 30.75L1.1 32.95Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M22.02 34.05L11.28 34.05L11.28 29.65L22.02 29.65L22.02 34.05ZM12.38 32.95L20.92 32.95L20.92 30.75L12.38 30.75L12.38 32.95Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M33.31 34.05L22.57 34.05L22.57 29.65L33.31 29.65L33.31 34.05ZM23.67 32.95L32.21 32.95L32.21 30.75L23.67 30.75L23.67 32.95Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M16.24 29.09L5.5 29.09L5.5 24.69L16.24 24.69L16.24 29.09ZM6.6 27.99L15.14 27.99L15.14 25.79L6.6 25.79L6.6 27.99Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M27.53 29.09L16.79 29.09L16.79 24.69L27.53 24.69L27.53 29.09ZM17.89 27.99L26.42 27.99L26.42 25.79L17.89 25.79L17.89 27.99Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M33.31 29.09L28.08 29.09L28.08 24.69L33.31 24.69L33.31 25.79L29.18 25.79L29.18 27.99L33.31 27.99L33.31 29.09Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M4.95 29.09L0 29.09L0 27.99L3.85 27.99L3.85 25.79L0 25.79L0 24.69L4.95 24.69L4.95 29.09Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M10.73 24.14L0 24.14L0 19.73L10.73 19.73L10.73 24.14ZM1.1 23.04L9.63 23.04L9.63 20.84L1.1 20.84L1.1 23.04Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M22.02 24.14L11.28 24.14L11.28 19.73L22.02 19.73L22.02 24.14ZM12.38 23.04L20.92 23.04L20.92 20.84L12.38 20.84L12.38 23.04Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M33.31 24.14L22.57 24.14L22.57 19.73L33.31 19.73L33.31 24.14ZM23.67 23.04L32.21 23.04L32.21 20.84L23.67 20.84L23.67 23.04Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M16.24 19.18L5.5 19.18L5.5 14.78L16.24 14.78L16.24 19.18ZM6.6 18.08L15.14 18.08L15.14 15.88L6.6 15.88L6.6 18.08Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M27.53 19.18L16.79 19.18L16.79 14.78L27.53 14.78L27.53 19.18ZM17.89 18.08L26.42 18.08L26.42 15.88L17.89 15.88L17.89 18.08Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M33.31 19.18L28.08 19.18L28.08 14.78L33.31 14.78L33.31 15.88L29.18 15.88L29.18 18.08L33.31 18.08L33.31 19.18Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M4.95 19.18L0 19.18L0 18.08L3.85 18.08L3.85 15.88L0 15.88L0 14.78L4.95 14.78L4.95 19.18Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M10.73 14.23L0 14.23L0 9.82L10.73 9.82L10.73 14.23ZM1.1 13.13L9.63 13.13L9.63 10.92L1.1 10.92L1.1 13.13Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M22.02 14.23L11.28 14.23L11.28 9.82L22.02 9.82L22.02 14.23ZM12.38 13.13L20.92 13.13L20.92 10.92L12.38 10.92L12.38 13.13Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <g opacity="0.300000">
      <path id="Vector" d="M23.12 13.13L33.31 13.13L33.31 14.23L23.12 14.23L23.12 13.13Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
  </g>
  <path id="Vector" d="M4.95 9.27L0 9.27L0 8.17L3.85 8.17L3.85 5.97L0 5.97L0 4.87L4.95 4.87L4.95 9.27Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
  <path id="Vector" d="M23.92 7.79L22.41 3.66L32.48 0L34 4.12L23.92 7.79ZM23.84 4.29L24.58 6.35L32.59 3.44L31.85 1.37L23.84 4.29Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
</svg>
`,
  `<svg width="33.000000" height="39.000000" viewBox="0 0 33 39" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <desc>
           Created with Pixso.
   </desc>
   <defs/>
   <path id="Vector" d="M33 18.92L33 10.7L31.45 10.7L31.45 6.8L31.45 6.18L31.45 0L30.21 0L30.21 6.18L3.4 6.18L3.4 10.7L2.72 10.7L2.72 11.93L3.4 11.93L3.4 16.45L9.64 16.45L9.64 38.35L10.88 38.35L10.88 16.45L31.45 16.45L31.45 11.93L31.76 11.93L31.76 17.87L16.29 20.44L16.29 26.78L14.5 26.78L14.5 38.35L19.36 38.35L19.36 26.81L17.53 26.81L17.53 21.49L33 18.92ZM30.21 15.21L4.63 15.21L4.63 7.42L30.21 7.42L30.21 15.21ZM18.09 37.11L15.71 37.11L15.71 28.05L18.09 28.05L18.09 37.11Z" fill="#FEFCFA" fill-opacity="1.000000" fill-rule="nonzero"/>
   <g opacity="0.300000">
       <path id="Vector" d="M5.31 21.64L6.55 21.64L6.55 22.88L5.31 22.88L5.31 21.64Z" fill="#FEFCFA" fill-opacity="1.000000" fill-rule="evenodd"/>
   </g>
   <g opacity="0.300000">
       <path id="Vector" d="M25.73 0L26.96 0L26.96 1.23L25.73 1.23L25.73 0Z" fill="#FEFCFA" fill-opacity="1.000000" fill-rule="evenodd"/>
   </g>
   <g opacity="0.300000">
       <path id="Vector" d="M19.54 3.09L20.78 3.09L20.78 4.33L19.54 4.33L19.54 3.09Z" fill="#FEFCFA" fill-opacity="1.000000" fill-rule="evenodd"/>
   </g>
   <g opacity="0.300000">
       <path id="Vector" d="M7.17 0L8.41 0L8.41 1.23L7.17 1.23L7.17 0Z" fill="#FEFCFA" fill-opacity="1.000000" fill-rule="evenodd"/>
   </g>
   <g opacity="0.300000">
       <path id="Vector" d="M0.06 3.09L1.29 3.09L1.29 4.33L0.06 4.33L0.06 3.09Z" fill="#FEFCFA" fill-opacity="1.000000" fill-rule="evenodd"/>
   </g>
   <g opacity="0.300000">
       <path id="Vector" d="M5.31 32.16L6.55 32.16L6.55 38.35L5.31 38.35L5.31 32.16Z" fill="#FEFCFA" fill-opacity="1.000000" fill-rule="evenodd"/>
   </g>
   <g opacity="0.300000">
       <path id="Vector" d="M0 34.39L1.23 34.39L1.23 35.62L0 35.62L0 34.39Z" fill="#FEFCFA" fill-opacity="1.000000" fill-rule="evenodd"/>
   </g>
   <g opacity="0.300000">
       <path id="Vector" d="M0 18.92L1.23 18.92L1.23 25.11L0 25.11L0 18.92Z" fill="#FEFCFA" fill-opacity="1.000000" fill-rule="evenodd"/>
   </g>
</svg>
`,
  `<svg width="37.000000" height="24.000000" viewBox="0 0 37 24" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<desc>
        Created with Pixso.
</desc>
<defs/>
<path id="Vector" d="M9.7 8.76L13.95 8.79L13.95 1.21L9.7 1.21L9.7 1.24C7.97 1.39 6.61 2.72 6.39 4.42L0 4.42L0 5.64L6.39 5.64C6.61 7.3 7.97 8.64 9.7 8.76ZM10.91 2.42L12.73 2.42L12.73 7.58L10.91 7.58L10.91 2.42ZM7.58 4.85C7.58 3.6 8.52 2.6 9.7 2.45L9.7 7.58C8.52 7.43 7.58 6.39 7.58 5.18L7.58 4.85Z" fill="#FAF4F0" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M29.72 1.81L32.75 1.81L32.75 3.03L29.72 3.03L29.72 1.81Z" fill="#FAF4F0" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M29.72 3.63L32.75 3.63L32.75 4.85L29.72 4.85L29.72 3.63Z" fill="#FAF4F0" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M12.28 12L12.28 13.22L13.04 13.22L13.04 22.68L12.28 22.68L12.28 23.89L18.04 23.89L18.04 22.68L17.28 22.68L17.28 13.22L18.04 13.22L18.04 12L15.77 12L15.77 10L23.35 10L23.35 13.04L24.86 13.04L25.47 13.04C25.99 13.04 26.38 13.43 26.38 13.95L26.38 23.89L30.32 23.89C31.51 23.89 32.45 22.95 32.45 21.77L32.45 9.97C34.99 9.73 37 7.61 37 5C37 2.24 34.75 0 31.99 0L14.55 0L14.55 9.4L14.55 10L14.55 12L12.28 12ZM24.56 11.82L24.56 10L24.86 10L24.86 11.82L24.56 11.82ZM15.77 1.21L31.99 1.21C34.08 1.21 35.78 2.91 35.78 5C35.78 7.09 34.08 8.79 31.99 8.79L31.23 8.79L31.23 21.77C31.23 22.29 30.84 22.68 30.32 22.68L27.59 22.68L27.59 13.95C27.59 12.98 26.96 12.19 26.08 11.91L26.08 10L27.59 10L27.59 8.79L26.08 8.79L25.47 8.79L15.77 8.79L15.77 1.21ZM16.07 22.68L14.25 22.68L14.25 13.22L16.07 13.22L16.07 22.68Z" fill="#FAF4F0" fill-opacity="1.000000" fill-rule="nonzero"/>
</svg>
`,
  `<svg width="31.000000" height="31.000000" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<desc>
        Created with Pixso.
</desc>
<defs/>
<path id="Vector" d="M14.43 15.01L15.44 16.02L6.11 25.35L5.1 24.34L14.43 15.01Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M4.07 27C4.07 27.34 3.8 27.61 3.46 27.61C3.13 27.61 2.86 27.34 2.86 27C2.86 26.67 3.13 26.4 3.46 26.4C3.8 26.4 4.07 26.67 4.07 27Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M25.03 6.19C24.68 6.08 24.35 5.79 24.25 5.43C24.14 5.08 24.25 4.68 24.5 4.4C24.75 4.11 25.14 4 25.54 4.11C25.89 4.22 26.21 4.5 26.32 4.86C26.43 5.22 26.32 5.61 26.07 5.9C25.79 6.15 25.39 6.29 25.03 6.19Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M30.19 6.19L30.15 6.01L29.86 5.72L28.68 1.64L24.78 0.68L24.43 0.32L24.25 0.28C21.35 -0.5 18.31 0.35 16.2 2.43C16.2 2.43 16.2 2.43 16.16 2.47C14.45 4.18 13.62 6.51 13.73 8.8L13.73 9.08C13.7 10.73 12.05 13.48 10.62 14.91L1 24.54C0.35 25.22 0 26.07 0 27C0 27.93 0.35 28.83 1 29.47C1.68 30.15 2.57 30.47 3.46 30.47C4.36 30.47 5.22 30.15 5.9 29.47L15.52 19.85C16.95 18.38 19.7 16.77 21.35 16.74C21.6 16.77 21.85 16.77 22.1 16.77C24.36 16.77 26.43 15.92 28.04 14.31C30.15 12.2 30.97 9.08 30.19 6.19ZM24.43 2.04L27.5 2.82L28.4 5.94L26.14 8.26L23 7.47L22.1 4.36L23 3.47L24.43 2.04ZM25 9.44L24.32 10.12C22.28 9.83 20.63 8.19 20.35 6.15L20.99 5.51L21.89 8.65L25 9.44ZM14.52 18.81L4.9 28.44C4.11 29.22 2.79 29.22 2 28.44C1.6 28.08 1.39 27.54 1.39 27C1.39 26.47 1.6 25.97 2 25.57L11.62 15.92C12.51 15.02 13.7 13.41 14.45 11.8C14.84 12.73 15.41 13.59 16.16 14.31C16.88 15.02 17.74 15.59 18.63 16.02C16.99 16.77 15.41 17.92 14.52 18.81ZM27 13.27C25.71 14.52 24.03 15.24 22.25 15.27L21.74 15.31C20.03 15.24 18.38 14.52 17.2 13.27C15.91 11.98 15.27 10.37 15.16 8.69L15.2 8.19C15.23 6.62 15.77 5.11 16.77 3.93C16.91 3.75 17.06 3.61 17.2 3.43C18.74 1.89 20.85 1.18 22.96 1.46L21.99 2.47L18.88 5.61L18.92 5.94C19.13 8.94 21.57 11.37 24.57 11.59L24.89 11.62L26.64 9.87L26.9 9.58L28.97 7.51C29.26 9.58 28.54 11.77 27 13.27Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
</svg>
`,
  `<svg width="31.000000" height="32.000000" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<desc>
        Created with Pixso.
</desc>
<defs/>
<path id="Vector" d="M31 4.11L31 0L22.51 0L22.51 4.11L23.66 4.11L23.66 17.75L22.51 17.75L22.51 21.86L23.61 21.86C23.25 24.07 21.32 25.72 19.03 25.72C16.74 25.72 14.84 24.05 14.48 21.86L15.56 21.86L15.56 17.75L14.38 17.75C13.99 12.32 9.57 7.97 4.11 7.71L4.11 6.68L0 6.68L0 15.17L4.11 15.17L4.11 13.91C6.17 14.14 7.82 15.71 8.15 17.75L7.07 17.75L7.07 21.86L8.25 21.86C8.64 27.47 13.32 31.9 19.03 31.9C24.77 31.9 29.43 27.52 29.81 21.86L31 21.86L31 17.75L29.84 17.75L29.84 4.11L31 4.11ZM3.08 14.14L1.02 14.14L1.02 7.71L3.08 7.71L3.08 14.14ZM4.11 12.88L4.11 8.74C9 9 12.96 12.86 13.35 17.72L9.2 17.72C8.84 15.15 6.74 13.12 4.11 12.88ZM8.1 20.83L8.1 18.78L14.53 18.78L14.53 20.83L8.1 20.83ZM19.03 30.87C13.89 30.87 9.67 26.88 9.28 21.86L13.42 21.86C13.81 24.62 16.18 26.75 19.03 26.75C21.86 26.75 24.25 24.64 24.64 21.86L28.78 21.86C28.4 26.93 24.18 30.87 19.03 30.87ZM29.97 18.78L29.97 20.83L23.53 20.83L23.53 18.78L29.97 18.78ZM24.69 17.75L24.69 4.11L28.81 4.11L28.81 17.75L24.69 17.75ZM23.53 3.08L23.53 1.02L29.97 1.02L29.97 3.08L23.53 3.08Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<g opacity="0.300000">
    <path id="Vector" d="M22.58 29.45C24.72 28.55 26.44 26.78 27.29 24.64L26.34 24.25C25.59 26.16 24.07 27.7 22.2 28.5L22.58 29.45Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
</g>
</svg>
`,
  `<svg width="34.000000" height="38.000000" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<desc>
        Created with Pixso.
</desc>
<defs/>
<path id="Vector" d="M29.75 36.42L0 36.42L0 37.64L29.75 37.64C32.08 37.64 34 35.73 34 33.39C34 31.05 32.08 29.14 29.75 29.14L23.37 29.14L23.37 28.53L31.57 28.53L31.57 27.01C33.12 25.74 34 23.89 34 21.85L34 8.19C34 4.7 31.32 1.85 27.92 1.54L27.92 0L5.46 0L5.46 4.25L27.92 4.25L27.92 2.76C30.66 3.06 32.78 5.37 32.78 8.19L32.78 21.85C32.78 23.13 32.36 24.34 31.57 25.28L31.57 8.8L1.82 8.8L1.82 28.53L10.01 28.53L10.01 29.14L7.58 29.14L7.58 30.35L10.01 30.35L10.01 32.17L23.37 32.17L23.37 30.35L29.75 30.35C31.41 30.35 32.78 31.72 32.78 33.39C32.78 35.06 31.41 36.42 29.75 36.42ZM26.71 3.03L6.67 3.03L6.67 1.21L26.71 1.21L26.71 3.03ZM3.03 10.01L30.35 10.01L30.35 27.32L3.03 27.32L3.03 10.01ZM11.23 30.96L11.23 28.53L22.16 28.53L22.16 30.96L11.23 30.96Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M3.94 26.41L29.44 26.41L29.44 10.92L3.94 10.92L3.94 26.41ZM16.08 25.19L11.23 25.19L11.23 12.14L16.08 12.14L16.08 25.19ZM17.3 12.14L22.16 12.14L22.16 25.19L17.3 25.19L17.3 12.14ZM28.23 25.19L23.37 25.19L23.37 12.14L28.23 12.14L28.23 25.19ZM5.16 12.14L10.01 12.14L10.01 25.19L5.16 25.19L5.16 12.14Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
</svg>
`,
  `<svg width="31.000000" height="36.000000" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<desc>
        Created with Pixso.
</desc>
<defs/>
<path id="Vector" d="M2.89 35.34L0 35.34L0 12.16C0 6.57 4.54 2.02 10.14 2.02L31 2.02L31 4.92L10.14 4.92C6.14 4.92 2.89 8.17 2.89 12.16L2.89 35.34ZM1.15 34.18L1.73 34.18L1.73 12.16C1.73 7.53 5.5 3.76 10.14 3.76L29.84 3.76L29.84 3.18L10.14 3.18C5.18 3.18 1.15 7.21 1.15 12.16L1.15 34.18Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M12.4 35.34L11.24 35.34L11.24 6.37C11.24 5.64 11.35 4.92 11.58 4.25L12.68 4.63C12.48 5.18 12.4 5.76 12.4 6.37L12.4 35.34Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M21.55 2.92C20.57 1.79 19.15 1.15 17.64 1.15C16.16 1.15 14.74 1.79 13.76 2.89L12.89 2.11C14.1 0.78 15.84 0 17.64 0C19.46 0 21.23 0.78 22.42 2.17L21.55 2.92Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M22.88 10.51L22.85 6.37C22.85 5.79 22.74 5.21 22.56 4.66L23.66 4.28C23.9 4.95 24.01 5.67 24.04 6.4L24.04 10.51L22.88 10.51Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M22.88 15.84L24.04 15.84L24.04 23.03L22.88 23.03L22.88 15.84Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M26.65 16.42L20.19 16.42L20.19 13.18C20.19 11.38 21.64 9.93 23.43 9.93C25.23 9.93 26.68 11.38 26.68 13.18L26.68 16.42L26.65 16.42ZM21.35 15.26L25.49 15.26L25.49 13.18C25.49 12.02 24.56 11.09 23.4 11.09C22.25 11.09 21.32 12.02 21.32 13.18L21.32 15.26L21.35 15.26Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M23.46 31.57C23.32 31.57 23.14 31.57 23 31.55C19.41 31.34 16.51 28.47 16.25 24.91C16.07 22.65 16.94 20.48 18.65 18.97C19.64 18.1 20.19 16.83 20.19 15.49L20.77 15.49L20.77 15.29L26.07 15.29L26.07 15.44L26.65 15.44C26.65 16.77 27.26 18.07 28.27 19C29.81 20.33 30.71 22.3 30.71 24.33C30.71 26.27 29.95 28.07 28.59 29.43C27.2 30.82 25.4 31.57 23.46 31.57ZM21.26 16.42C21.06 17.73 20.39 18.94 19.41 19.84C17.99 21.12 17.26 22.94 17.41 24.82C17.64 27.81 20.07 30.21 23.06 30.42C24.82 30.53 26.5 29.89 27.75 28.65C28.91 27.49 29.52 25.98 29.52 24.36C29.52 22.65 28.79 21 27.49 19.84C26.48 18.91 25.81 17.73 25.58 16.42L21.26 16.42Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M21.14 22.45L25.81 22.45L25.81 23.61L21.14 23.61L21.14 22.45Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
</svg>
`,
  `<svg width="44.000000" height="36.000000" viewBox="0 0 44 36" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<desc>
        Created with Pixso.
</desc>
<defs/>
<path id="Vector" d="M19.86 6.99L4.29 15.88L8.92 15.88L8.92 27.3L11.47 27.3L11.47 18.15L18.07 18.15L18.07 27.3L30.8 27.3L30.8 15.88L35.44 15.88L19.86 6.99ZM24.25 22.78L19.93 22.78L19.93 18.25L24.25 18.25L24.25 22.78ZM29.07 22.78L24.74 22.78L24.74 18.25L29.07 18.25L29.07 22.78Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M32.68 28.6L7.04 28.6L7.04 29.9L5.39 29.9L5.39 30.38L7.07 30.38L7.07 30.14L8.09 30.14L8.09 30.38L9.77 30.38L9.77 30.14L10.79 30.14L10.79 30.38L12.47 30.38L12.47 30.14L13.49 30.14L13.49 30.38L15.17 30.38L15.17 30.14L16.19 30.14L16.19 30.38L17.87 30.38L17.87 30.14L18.88 30.14L18.88 30.38L20.56 30.38L20.56 30.14L21.58 30.14L21.58 30.38L23.26 30.38L23.26 30.14L24.28 30.14L24.28 30.38L25.96 30.38L25.96 30.14L26.98 30.14L26.98 30.38L28.66 30.38L28.66 30.14L29.68 30.14L29.68 30.38L31.36 30.38L31.36 30.14L32.38 30.14L32.38 30.38L34.06 30.38L34.06 29.9L32.68 29.9L32.68 28.6Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M16.19 4.28L17.86 4.28L17.86 4.76L16.19 4.76L16.19 4.28Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M10.79 4.28L12.47 4.28L12.47 4.76L10.79 4.76L10.79 4.28Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M18.88 4.28L20.56 4.28L20.56 4.76L18.88 4.76L18.88 4.28Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M0 4.28L1.67 4.28L1.67 4.76L0 4.76L0 4.28Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M13.49 4.28L15.17 4.28L15.17 4.76L13.49 4.76L13.49 4.28Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M32.38 4.28L34.06 4.28L34.06 4.76L32.38 4.76L32.38 4.28Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M40.47 4.28L42.15 4.28L42.15 4.76L40.47 4.76L40.47 4.28Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M21.58 4.28L23.26 4.28L23.26 4.76L21.58 4.76L21.58 4.28Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M29.68 4.28L31.36 4.28L31.36 4.76L29.68 4.76L29.68 4.28Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M26.98 4.28L28.66 4.28L28.66 4.76L26.98 4.76L26.98 4.28Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M24.28 4.28L25.96 4.28L25.96 4.76L24.28 4.76L24.28 4.28Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M5.39 4.28L7.07 4.28L7.07 4.76L5.39 4.76L5.39 4.28Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M0 29.9L1.67 29.9L1.67 30.38L0 30.38L0 29.9Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M40.47 29.9L42.15 29.9L42.15 30.38L40.47 30.38L40.47 29.9Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M4.37 29.9L2.69 29.9L2.69 30.38L4.05 30.38L4.05 31.92L4.53 31.92L4.53 30.24L4.37 30.24L4.37 29.9Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M39.07 25.87L39.55 25.87L39.55 27.55L39.07 27.55L39.07 25.87Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M39.07 23.17L39.55 23.17L39.55 24.85L39.07 24.85L39.07 23.17Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M39.07 29.9L37.77 29.9L37.77 30.38L39.45 30.38L39.45 30.1L39.55 30.1L39.55 28.57L39.07 28.57L39.07 29.9Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M39.07 17.78L39.55 17.78L39.55 19.46L39.07 19.46L39.07 17.78Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M39.07 20.47L39.55 20.47L39.55 22.15L39.07 22.15L39.07 20.47Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M39.07 9.68L39.55 9.68L39.55 11.36L39.07 11.36L39.07 9.68Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M39.07 6.98L39.55 6.98L39.55 8.66L39.07 8.66L39.07 6.98Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M39.07 5.96L39.55 5.96L39.55 4.28L39.45 4.28L39.07 4.28L37.77 4.28L37.77 4.76L39.07 4.76L39.07 5.96Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M39.07 12.38L39.55 12.38L39.55 14.06L39.07 14.06L39.07 12.38Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M9.09 6.63L9.57 6.63L9.57 4.76L9.77 4.76L9.77 4.28L9.57 4.28L9.57 0.74L9.09 0.74L9.09 4.28L8.09 4.28L8.09 4.76L9.09 4.76L9.09 6.63Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M39.55 15.75L39.55 15.08L39.07 15.08L39.07 15.75L37.2 15.75L37.2 16.23L39.07 16.23L39.07 16.76L39.55 16.76L39.55 16.23L43.65 16.23L43.65 15.75L39.55 15.75Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M34.87 8.09L35.35 8.09L35.35 9.77L34.87 9.77L34.87 8.09Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M34.87 26.98L35.35 26.98L35.35 28.66L34.87 28.66L34.87 26.98Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M34.87 21.58L35.35 21.58L35.35 23.26L34.87 23.26L34.87 21.58Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M34.87 16.19L35.35 16.19L35.35 17.87L34.87 17.87L34.87 16.19Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M34.87 18.88L35.35 18.88L35.35 20.56L34.87 20.56L34.87 18.88Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M35.35 2.69L34.87 2.69L34.87 4.37L35.08 4.37L35.08 4.76L36.76 4.76L36.76 4.28L35.35 4.28L35.35 2.69Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M34.87 24.28L35.35 24.28L35.35 25.96L34.87 25.96L34.87 24.28Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M34.87 32.38L35.35 32.38L35.35 33.7L34.87 33.7L34.87 32.38Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M34.87 5.39L35.35 5.39L35.35 7.07L34.87 7.07L34.87 5.39Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M34.87 13.49L35.35 13.49L35.35 15.17L34.87 15.17L34.87 13.49Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M35.35 29.68L34.87 29.68L34.87 31.36L35.35 31.36L35.35 30.38L36.76 30.38L36.76 29.9L35.35 29.9L35.35 29.68Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M34.87 10.79L35.35 10.79L35.35 12.47L34.87 12.47L34.87 10.79Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M34.87 0L35.35 0L35.35 1.67L34.87 1.67L34.87 0Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M4.05 11.35L4.53 11.35L4.53 13.03L4.05 13.03L4.05 11.35Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M4.05 0.55L4.53 0.55L4.53 2.23L4.05 2.23L4.05 0.55Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M4.05 19.44L4.53 19.44L4.53 21.12L4.05 21.12L4.05 19.44Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M4.05 8.65L4.53 8.65L4.53 10.33L4.05 10.33L4.05 8.65Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M4.05 22.14L4.53 22.14L4.53 23.82L4.05 23.82L4.05 22.14Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M4.05 24.84L4.53 24.84L4.53 26.52L4.05 26.52L4.05 24.84Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M4.05 32.94L4.53 32.94L4.53 34.26L4.05 34.26L4.05 32.94Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M4.05 4.93L4.53 4.93L4.53 3.25L4.05 3.25L4.05 4.28L2.69 4.28L2.69 4.76L4.05 4.76L4.05 4.93Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
<path id="Vector" d="M4.05 27.54L4.53 27.54L4.53 29.22L4.05 29.22L4.05 27.54Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M4.05 5.95L4.53 5.95L4.53 7.63L4.05 7.63L4.05 5.95Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M4.05 16.75L4.53 16.75L4.53 18.42L4.05 18.42L4.05 16.75Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M4.05 14.05L4.53 14.05L4.53 15.73L4.05 15.73L4.05 14.05Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
<path id="Vector" d="M18.03 34.21L11.25 34.21L11.25 32.94L10.77 32.94L10.77 34.21L10.77 34.69L10.77 35.96L11.25 35.96L11.25 34.69L18.03 34.69L18.03 35.96L18.51 35.96L18.51 32.94L18.03 32.94L18.03 34.21Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
</svg>
`,
];
