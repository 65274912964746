import React from "react";
import Skeleton from "react-loading-skeleton";
<Skeleton />;
const ProductSkeleton = () => {
  return (
    <>
      <div className="cartelement_top row">
        <div className="product_photo col-6 col-sm-12">
          <Skeleton className="product_photo_slider_full" />
          <Skeleton className="product_photo_slider_smile_preview" />
        </div>
        <div className="product_content_inf col-6 col-sm-12">
          <Skeleton height={"100%"} />
          <div className="product_content_inf_b">
            <div className="prices">
              <div className="price_desc">
                <Skeleton />
              </div>
              <div className="price">
                <Skeleton />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cartelement_tabs">
        <div className="cartelement_tab">
          <Skeleton />
        </div>
        <div className="cartelement_tab_content">
          <div className={`cartelement_tab_content_item active`}>
            <div className="for_tables">
              <div className="for_table">
                <Skeleton />
              </div>
              <div className="for_table">
                <div className="for_table_1">
                  <Skeleton />
                </div>
              </div>
              <div className="for_table">
                <div className="for_table_1">
                  <Skeleton />
                </div>
              </div>
              <div className="for_table">
                <div className="for_table_1">
                  <Skeleton />
                </div>
              </div>
              <div className="for_table">
                <div className="for_table_1">
                  <Skeleton />
                </div>
              </div>
              <div className="for_table">
                <div className="for_table_1">
                  <Skeleton />
                </div>
              </div>
              <div className="for_table">
                <div className="for_table_1">
                  <Skeleton />
                </div>
              </div>
              <div className="for_table">
                <div className="for_table_1">
                  <Skeleton />
                </div>
              </div>
            </div>
          </div>
          <p v-html="sanitizeHtml(info)"></p>
          <h2>
            {" "}
            <Skeleton />
          </h2>
          <p>
            <Skeleton />
          </p>
          <p v-html="sanitizeHtml(equipment)"></p>
          <h2>
            {" "}
            <Skeleton />
          </h2>
          <p>
            <Skeleton />
          </p>
          <p v-html="sanitizeHtml(functions)"></p>
        </div>
      </div>
    </>
  );
};

export default ProductSkeleton;
