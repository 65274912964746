import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./burgerMenu.css"; // Make sure to create this CSS file
import { ROUTES } from "../../models/constant";

type BurgerMenuType = {
  isOpen: boolean;
};

const BurgerMenu: React.FC<BurgerMenuType> = ({ isOpen }) => {
  const [menuClass, setMenuClass] = useState("burger-menu-container");

  useEffect(() => {
    if (isOpen) {
      setMenuClass("burger-menu-container open");
    } else {
      setMenuClass("burger-menu-container");
    }
  }, [isOpen]);
  return (
    <>
      {isOpen && (
        <div className={menuClass}>
          <div className="burger-menu">
            <div className="burger_logo">
              <Link to={ROUTES.main} className="logo"></Link>
            </div>
            <div className="burger_list">
              <Link to={ROUTES.main}>Главная</Link>
              <Link to={ROUTES.catalog + "/1"}>Каталог</Link>
              <Link to={ROUTES.delivery}>Доставка</Link>
              <Link to={ROUTES.contacts}>Контакты</Link>
            </div>
            <div className="burger_contant">
              <a href="tel:+7 495 979 88 98" className="burger_phone">
                <b>+7 495</b> 979 88 98
              </a>

              <div className="copyryght">
                1996 - 2023, ООО «Будем Строить» Стройматериалы и инструменты
                оптом и мелким оптом
              </div>
            </div>
          </div>
          <div className="burger-overlay"></div>
        </div>
      )}
    </>
  );
};

export default BurgerMenu;
