import React from "react";
import MainbannerFormOnly from "../mainBannerFormOnly/mainBannerFormOnly";



const MainbannerForm: React.FC = () => {
  return (
    <div className="mainbanner_form">
      <div className="logo"></div>
      <MainbannerFormOnly />
    </div>
  );
};

export default MainbannerForm;
