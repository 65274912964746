import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { PreloadedCategoriesArr, ROUTES } from "../../models/constant";
// import { BACKEND_URL_API_PRICELIST } from "@/app/stores/helper";
import AsideNavItem from "./common/asideNavItem";
import {  useGetCategoryTreeQuery } from "../../redux/service/api";


const AsideNav: React.FC = () => {
  const { data: categories, isLoading } = useGetCategoryTreeQuery();



  return (
    <div className="header_menu_wrap">
      <div className="header_menu">
        <Link to={ROUTES.main} className="logo"></Link>
        <ul className="header_menu_nav">
          {isLoading
            ? PreloadedCategoriesArr.map((category, index) => (
                <AsideNavItem
                  key={category.id}
                  index={index}
                  category={category}
                />
              ))
            : categories?.map((category, index) => (
                <AsideNavItem
                  key={category.id}
                  index={index}
                  category={category}
                />
              ))}

          <li className="header_menu_item">
            <div className="header_menu_item_wrap">
              <a download>
                <span>скачать прай-лист</span>
              </a>
            </div>
          </li>
        </ul>
        <div className="promobanner">
          <Link to="/catalog/new">
            <img src="/images/banner_to_catalog.png" alt="Promo Banner" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AsideNav;
