
import React, { useState, useRef } from "react";
import Modal from "../modal/modal";
import MainbannerForm from "../mainBannerForm/mainBannerForm";

const FeedBack: React.FC = () => {
  const [activeModal, setActiveModal] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const openModal = () => {
    setActiveModal(true);
  };

  const closeModal = () => {
    setActiveModal(false);
  };

  return (
    <div>
      <button className="btn js_order_call row" onClick={openModal}>
        <div className="icon">
          {" "}
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.38801 2.55312C5.73903 2.94306 5.62803 3.38461 5.31724 3.80334C5.23635 3.91018 5.14897 4.01194 5.05563 4.1081C5.0004 4.16582 4.94441 4.22282 4.88761 4.27906L4.42741 4.73979C4.36744 4.79979 4.77902 5.62166 5.57763 6.42133C6.37564 7.21982 7.19762 7.63198 7.25823 7.57138L7.71844 7.11124C7.97162 6.85809 8.10541 6.73571 8.28961 6.61511C8.67243 6.36315 9.09062 6.29535 9.44283 6.61032C10.593 7.43337 11.244 7.93793 11.5632 8.26908C12.1854 8.91576 12.1032 9.91044 11.5668 10.4779C11.3808 10.6747 11.1444 10.9111 10.8654 11.1798C9.1788 12.8674 5.61841 11.8403 2.88963 9.11013C0.160839 6.37814 -0.866358 2.81768 0.817837 1.13373C1.11964 0.826573 1.21984 0.726991 1.51384 0.437234C2.06163 -0.102685 3.10261 -0.187272 3.73562 0.437234C4.06804 0.766585 4.59902 1.44868 5.38801 2.55251V2.55312ZM8.56621 7.95953L8.1066 8.41962C7.32481 9.20134 5.99404 8.53481 4.72921 7.26962C3.46384 6.0032 2.79783 4.67259 3.57963 3.89091L4.03924 3.43141C4.11302 3.3576 4.14724 3.32342 4.18684 3.28141C4.24203 3.22323 4.28701 3.17224 4.32364 3.12723C3.62402 2.15358 3.14882 1.54527 2.89263 1.2915C2.76063 1.16132 2.46424 1.18532 2.35503 1.2927C2.06523 1.57826 1.96983 1.67365 1.66864 1.9796C0.586239 3.06244 1.41484 5.936 3.73862 8.26125C6.06062 10.5859 8.93464 11.415 10.0254 10.3237C10.2996 10.0592 10.5234 9.83485 10.6956 9.65305C10.8204 9.52106 10.8426 9.25051 10.6992 9.10115C10.461 8.85397 9.8772 8.39925 8.8692 7.67517C8.76349 7.76478 8.66235 7.85967 8.56621 7.95953Z"
              fill="white"
            />
          </svg>
        </div>
        <i>Обратный звонок</i>
      </button>
      {activeModal && (
        <Modal ref={modalRef} closeModal={closeModal}>
          <h1 className="close_icons" onClick={closeModal}>
            x
          </h1>
          <MainbannerForm  />
        </Modal>
      )}
    </div>
  );
};

export default FeedBack;
