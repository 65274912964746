export const lastReceipts = [
  {
    id: 1,
    link: "1",
    name: "Дюбели Driva для гипсокартона",
    description: `Всё очень просто - половину всех купленных
            стройматериалов Вы получаете по себестоимости, то есть по оптовой
            цене!`,
  },
  {
    id: 2,
    link: "1",
    name: "Керамогранит - прочно и выгодно!",
    description: `Всё очень просто - половину всех купленных
            стройматериалов Вы получаете по себестоимости, то есть по оптовой
            цене!`,
  },
  {
    id: 3,
    link: "1",
    name: "Инструмент для захвата",
    description: `Всё очень просто - половину всех купленных
                стройматериалов Вы получаете по себестоимости, то есть по оптовой
                цене!`,
  },
  {
    id: 4,
    link: "1",
    name: "Дюбели Driva для гипсокартона",
    description: `Всё очень просто - половину всех купленных
                стройматериалов Вы получаете по себестоимости, то есть по оптовой
                цене!`,
  },
  {
    id: 5,
    link: "1",
    name: "Керамогранит - прочно и выгодно!",
    description: `Всё очень просто - половину всех купленных
                стройматериалов Вы получаете по себестоимости, то есть по оптовой
                цене!`,
  },
  {
    id: 6,
    link: "1",
    name: "Инструмент для захвата",
    description: `Всё очень просто - половину всех купленных
            стройматериалов Вы получаете по себестоимости, то есть по оптовой
            цене!`,
  },
];
