import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const ProductCardSkeleton = () => {
  return (
    <SkeletonTheme baseColor="#e0e0e0" highlightColor="#f5f5f5">
      <div className="icons">
        <Skeleton circle={true} />
      </div>
      <div className="photo">
        <Skeleton height={"100%"} />
      </div>
      <div className="name">
        <Skeleton height={"100%"} />
      </div>
      <div className="status">
        <Skeleton width={"100%"} />
      </div>
      <div className="prices">
        <Skeleton />
      </div>
      <div className="btns">
        <Skeleton />
      </div>
    </SkeletonTheme>
  );
};

export default ProductCardSkeleton;
