import React, { useState, useEffect, useCallback } from "react";
import { ProductButtonsDefault } from "../ProductButtonsDefault/index";
import Pagination from "../pagination/pagination";
import { getQuery } from "../../utils/url";
import { QueryParams } from "../../models/interface";
import { useGetProductWithPagingQuery } from "../../redux/service/api";
import ProductCardSkeleton from "../../utils/productCardSkeleton";
const ProductCart = React.lazy(() => import("../productCard/index"));

interface CatalogProps {
  catalogId: number;
}

const ProductCatalog: React.FC<CatalogProps> = ({ catalogId }) => {
  const [query, setQuery] = useState<QueryParams>({
    Items: 12,
    CategoryId: catalogId,
    Page: 1,
  });
  const { data: catalog, isLoading } = useGetProductWithPagingQuery(
    getQuery(query)
  );

  const changePage = (value: number) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      Page: value,
    }));
  };

  useEffect(() => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      CategoryId: catalogId,
      Page: 1,
    }));
  }, [catalogId]);

  const loadMoreItems = () => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      Items: prevQuery.Items + 12,
    }));
  };

  return (
    <div className="catalog_list  row">
      {isLoading ? (
        <>
          {Array.from({ length: 12 }, (_, index) => (
            <div key={index} className="product_cart">
              {ProductCardSkeleton()}
            </div>
          ))}
        </>
      ) : (
        <>
          {catalog && catalog.totalCountItems > 0
            ? catalog.items.map((product) => (
                <React.Suspense
                  key={product.id}
                  fallback={
                    <div className="product_cart">{ProductCardSkeleton()}</div>
                  }
                >
                  <ProductCart isLoading={isLoading} product={product}>
                    <ProductButtonsDefault product={product} />
                  </ProductCart>
                </React.Suspense>
              ))
            : ""}
        </>
      )}
      {catalog?.totalCountItems && catalog.totalCountItems > query.Items ? (
        <Pagination
          currentPage={query.Page}
          totalPages={Math.ceil(catalog?.totalCountItems / query.Items)}
          onShowMore={loadMoreItems}
          onPageChange={changePage}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductCatalog;
