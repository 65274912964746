import React, { Suspense, useEffect } from "react";
import MainBanner from "../../layout/headerMain/headerMain";
import LayoutMain from "../../layout/layoutMain/layoutMain";
import { Helmet } from "react-helmet";

const ProductsNew = React.lazy(
  () => import("../../components/productNew/index")
);
const ProductsRecommendation = React.lazy(
  () => import("../../components/productsRecomendation/index")
);
const Advantages = React.lazy(
  () => import("../../components/advantages/index")
);
const HowWork = React.lazy(() => import("../../components/howWork/index"));
const ProductsPopular = React.lazy(
  () => import("../../components/productPopular/index")
);
const Partners = React.lazy(() => import("../../components/partners/index"));
const ContactsBlock = React.lazy(
  () => import("../../components/contactsBlock/index")
);

const HomePage = () => {
  useEffect(() => {
    document.title =
      'Стройматериалы и инструменты - купить в интернет магазине "БУДЕМ СТРОИТЬ"';
    window.ym(96971179, "hit", window.location.href);
  }, []);

  return (
    <LayoutMain>
      <Helmet>
        <meta name="description" content="Описание главной страницы  " />
        <meta
          name="keywords"
          content="ключевое слово главной 1, ключевое слово главной 2"
        />
      </Helmet>
      <ProductsNew />
      <ProductsRecommendation />
      <Advantages />
      <HowWork />
      <ProductsPopular />
      <Partners />
      <ContactsBlock />
    </LayoutMain>
  );
};

export default HomePage;
