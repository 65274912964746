
import React, { FC, ReactNode, MouseEvent, forwardRef } from "react";

interface ModalProps {
  children?: ReactNode;
  closeModal: () => void;
}

const Modal = forwardRef<HTMLDivElement, ModalProps>(
  ({ closeModal, children }, ref) => {
    const handleBackgroundClick = (event: MouseEvent<HTMLDivElement>) => {
      if (event.target === event.currentTarget) {
        closeModal();
      }
    };

    return (
      <div className="modal" onClick={handleBackgroundClick} ref={ref}>
        <div className="modal__inner">{children}</div>
      </div>
    );
  }
);

export default Modal;
