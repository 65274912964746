import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../models/constant";
import { ProductType } from "../../models/types";
import {
  addItem,
  removeItem,
  updateItemCount,
} from "../../redux/slice/cartSlice";
import { useAppDispatch } from "../../hooks/typed-react-redux-hooks";
import "./productCard.css";
import DeleteIcon from "./common/deleteIcon";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const ProductCard: React.FC<{
  product: ProductType;
  children?: React.ReactNode;
  cartComponent?: boolean;
  isLoading?: boolean;
}> = ({ product, children, cartComponent, isLoading }) => {
  const [countCart, setCountCart] = useState<number>(product.count || 1);
  const dispatch = useAppDispatch();

  const countCartIncrement = (aa: number) => {
    setCountCart((prevCount) => prevCount + 1);
  };

  const countCartDecrement = (aa: number) => {
    setCountCart((prevCount) => prevCount - 1);
  };

  const handleUpdateItemCount = (newCount: number) => {
    if (newCount < 1) {
      if (cartComponent) {
        dispatch(removeItem(product.id));
      } else {
        return 1;
      }
      return;
    }
    setCountCart(newCount);
    dispatch(updateItemCount({ productId: product.id, newCount }));
  };

  const handleAddItem = () => {
    const itemToAdd = {
      item: { ...product, count: countCart },
      count: countCart,
    };
    dispatch(addItem(itemToAdd));
  };

  const handleRemoveItem = () => {
    dispatch(removeItem(product.id));
  };

  const productCartScale = () => {
    const productCarts = document.querySelectorAll(".product_cart");
    productCarts.forEach(
      (productCart: Element, index: number, parent: NodeListOf<Element>) => {
        if (productCart instanceof HTMLElement) {
          productCart.addEventListener("click", () => {
            productCart.style.transform = "scale(1.05)";
            productCart.style.transition = "0.5s";
          });
        }
      }
    );
  };

  return (
    <div className="product_cart">
      <>
        <div className="icons">{children}</div>
        <Link to={ROUTES.product + "/" + product?.id} className="photo">
          {product.imageUrl === null ? (
            <img src="/images/logos/no_image_800x800.png" alt={product.name} />
          ) : (
            <img src={product.imageUrl} alt={product.name} />
          )}
        </Link>
        <Link to={ROUTES.product + "/" + product?.id} className="name">
          {product?.name}
        </Link>
        <div className="status">
          {product?.availability && (
            <>
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.00033 16.6667C4.39783 16.6667 0.666992 12.9358 0.666992 8.33333C0.666992 3.73083 4.39783 0 9.00033 0C13.6028 0 17.3337 3.73083 17.3337 8.33333C17.3337 12.9358 13.6028 16.6667 9.00033 16.6667ZM9.00033 15C10.7684 15 12.4641 14.2976 13.7144 13.0474C14.9646 11.7971 15.667 10.1014 15.667 8.33333C15.667 6.56522 14.9646 4.86953 13.7144 3.61929C12.4641 2.36905 10.7684 1.66667 9.00033 1.66667C7.23222 1.66667 5.53652 2.36905 4.28628 3.61929C3.03604 4.86953 2.33366 6.56522 2.33366 8.33333C2.33366 10.1014 3.03604 11.7971 4.28628 13.0474C5.53652 14.2976 7.23222 15 9.00033 15ZM8.16949 11.6667L4.63366 8.13083L5.81199 6.9525L8.16949 9.31L12.8828 4.59583L14.062 5.77417L8.16949 11.6667Z"
                  fill="#09B01A"
                ></path>
              </svg>
              В наличии: <span>{product?.unit}</span>
            </>
          )}
        </div>
        <div className="prices">
          <div className="price">
            {product?.priceLess100000 ?? 0} ₽{" "}
            <text className="product_unit__mob">/{product.unit}</text>{" "}
          </div>
          <div className="price_desc">Цена за {product?.unit}</div>
        </div>

        <div className={cartComponent ? "cart_btns btns" : "btns"}>
          {" "}
          {!cartComponent && (
            <button className="btn" onClick={() => handleAddItem()}>
              заказать
            </button>
          )}
          <div className="count">
            <input
              type="button"
              value="-"
              onClick={() => handleUpdateItemCount(countCart - 1)}
            />
            <input
              type="number"
              step={1}
              min={1}
              max={9999}
              id="num_count"
              name="quantity"
              value={countCart}
              title="Qty"
              onChange={(e) => {
                const newCountRaw = e.target.value;
                const newCount = parseInt(newCountRaw, 10);
                if (!isNaN(newCount) && newCount >= 1 && newCount <= 9999) {
                  handleUpdateItemCount(newCount);
                }
              }}
            />
            <input
              type="button"
              value="+"
              onClick={() => handleUpdateItemCount(countCart + 1)}
            />
          </div>
        </div>
        {cartComponent && <DeleteIcon removeItem={handleRemoveItem} />}
      </>
    </div>
  );
};

/* {cartComponent ? (
   <div className="btns">
   {!cartComponent && (
          <button className="btn" onClick={() => handleAddItem()}>
            заказать
          </button>
        )}
        <div className="count">
          <input
            type="button"
            value="-"
            onClick={() => handleUpdateItemCount(countCart - 1)}
          />
          <input
            type="number"
            step={1}
            min={1}
            max={9999}
            id="num_count"
            name="quantity"
            value={countCart}
            title="Qty"
            onChange={(e) => {
              const newCountRaw = e.target.value;
              const newCount = parseInt(newCountRaw, 10);
              if (!isNaN(newCount) && newCount >= 1 && newCount <= 9999) {
                handleUpdateItemCount(newCount);
              }
            }}
          />
          <input
            type="button"
            value="+"
            onClick={() => handleUpdateItemCount(countCart + 1)}
          />
        </div>
      </div>
        ) : (
          <div className="btns">
            <button className="btn" onClick={() => handleAddItem()}>
              заказать
            </button>
            <div className="count">
              <input
                type="button"
                value="-"
                onClick={() => countCartDecrement(1)}
              />
              <input
                type="number"
                step={1}
                min={1}
                max={9999}
                id="num_count"
                name="quantity"
                maxLength={5}
                value={product.count ? product.count : countCart}
                title="Qty"
                onChange={(e) => {
                  const newCountRaw = e.target.value;
                  if (newCountRaw.length <= 5) {
                    const newCount = parseInt(newCountRaw, 10);
                    if (!isNaN(newCount) && newCount >= 1 && newCount <= 9999) {
                      setCountCart(newCount);
                      dispatch(
                        updateItemCount({ productId: product.id, newCount })
                      );
                    }
                  } else {
                    const trimmedValue = newCountRaw.slice(0, 5);
                    e.target.value = trimmedValue;
                    const newCount = parseInt(trimmedValue, 10);
                    setCountCart(newCount);
                    dispatch(
                      updateItemCount({ productId: product.id, newCount })
                    );
                  }
                }}
              />
              <input
                type="button"
                value="+"
                onClick={() => countCartIncrement(product.id)}
              />
            </div>
          </div>
        )} */
