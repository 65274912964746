import React, { useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./common_swiper.css";
// import "./styles.css";
import { ProductType } from "../../models/types";
import { useAppDispatch } from "../../hooks/typed-react-redux-hooks";
import { addItem, updateItemCount } from "../../redux/slice/cartSlice";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ProductComponent: React.FC<{
  product: ProductType;
  description: string;
  imageUrl: string;
  priceLess100000: number;
  id: number;
  categoryId: number;
  article: string;
  name: string;
  unit: string;
  availability: boolean;
  brand: string;
  manufacturerArticle: string;
  weight: number;
  priceMore100000: number;
  priceMore500000: number;
  updatedAt: string;
  countCart: number;
  characteristics: { key: string };
}> = ({ product, description, imageUrl, priceLess100000 }) => {
  const [useActiveImg, setActiveImg] = useState("");
  const dispatch = useAppDispatch();
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [switcher, setSwitcher] = useState(0);

  const [countCart, setCountCart] = useState<number>(1);

  const countCartIncrement = (aa: number) => {
    setCountCart((prevCount) => prevCount + 1);
  };

  const countCartDecrement = (aa: number) => {
    setCountCart((prevCount) => prevCount - 1);
  };

  const handleUpdateItemCount = (newCount: number) => {
    dispatch(updateItemCount({ productId: product.id, newCount }));
  };

  // useEffect(() => {
  //   if (countCart < 1) {
  //     setCountCart(1);
  //   }
  // }, [countCart]);

  const handleAddItem = () => {
    const itemToAdd = {
      item: { ...product, count: countCart },
      count: countCart,
    };
    dispatch(addItem(itemToAdd));
  };

  const trueImageUrl =
    imageUrl !== null ? "/images/logos/no_image_800x800.png" : imageUrl;
  // {product.imageUrl === null ? (
  //   <img src="/images/logos/no_image_800x800.png" alt={product.name} />
  // ) : (
  //   <img src={product.imageUrl} alt={product.name} />
  // )}
  console.log(trueImageUrl);

  return (
    <>
      <div className="cartelement_top row">
        <div className="product_photo col-6 col-sm-12">
          <Swiper
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="product_photo_slider_full"
          >
            <SwiperSlide>
              <img src={trueImageUrl} />
            </SwiperSlide>
          </Swiper>
          <Swiper
            onClick={setThumbsSwiper}
            // onSwiper={setThumbsSwiper}
            className="swiper-wrapper"
            style={{ marginTop: "10px" }}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
          >
            <SwiperSlide className="swiper-slide product_photo_slider_smile_preview_item swiper-slide-thumb-active swiper-slide-visible swiper-slide-active swiper-slide-next">
              <img src={trueImageUrl} />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="product_content_inf col-6 col-sm-12">
          <div className="status">
            <template v-if="availability">
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.00033 16.6667C4.39783 16.6667 0.666992 12.9358 0.666992 8.33333C0.666992 3.73083 4.39783 0 9.00033 0C13.6028 0 17.3337 3.73083 17.3337 8.33333C17.3337 12.9358 13.6028 16.6667 9.00033 16.6667ZM9.00033 15C10.7684 15 12.4641 14.2976 13.7144 13.0474C14.9646 11.7971 15.667 10.1014 15.667 8.33333C15.667 6.56522 14.9646 4.86953 13.7144 3.61929C12.4641 2.36905 10.7684 1.66667 9.00033 1.66667C7.23222 1.66667 5.53652 2.36905 4.28628 3.61929C3.03604 4.86953 2.33366 6.56522 2.33366 8.33333C2.33366 10.1014 3.03604 11.7971 4.28628 13.0474C5.53652 14.2976 7.23222 15 9.00033 15ZM8.16949 11.6667L4.63366 8.13083L5.81199 6.9525L8.16949 9.31L12.8828 4.59583L14.062 5.77417L8.16949 11.6667Z"
                  fill="#09B01A"
                />
              </svg>
              В наличии
            </template>
          </div>
          <div className="description">{description}</div>
          <div className="sub_description">
            <b>Комплектация</b>
            <p>
              Сварочный полуавтомат – 1 шт.; Горелка 3 м – 1 шт.; Кабель
              заземления, медь 3 м – 1шт.; Кабель с электрододержателем, медь
              1,6 м – 1 шт.; Газовый шланг 3 м – 1 шт.; Ролик 0,6-0,8 мм – 1 шт.
            </p>
          </div>
          <div className="product_content_inf_b">
            <div
              className="btns"
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <button className="btn" onClick={() => handleAddItem()}>
                заказать
              </button>
              <div className="count">
                <input
                  type="button"
                  value="-"
                  onClick={() => countCartDecrement(1)}
                />
                <input
                  type="number"
                  step={1}
                  min={1}
                  max={9999}
                  id="num_count"
                  name="quantity"
                  maxLength={5}
                  value={product.count ? product.count : countCart}
                  title="Qty"
                  onChange={(e) => {
                    const newCountRaw = e.target.value;
                    if (newCountRaw.length <= 5) {
                      const newCount = parseInt(newCountRaw, 10);
                      if (
                        !isNaN(newCount) &&
                        newCount >= 1 &&
                        newCount <= 9999
                      ) {
                        setCountCart(newCount);
                        dispatch(
                          updateItemCount({ productId: product.id, newCount })
                        );
                      }
                    } else {
                      const trimmedValue = newCountRaw.slice(0, 5);
                      e.target.value = trimmedValue;
                      const newCount = parseInt(trimmedValue, 10);
                      setCountCart(newCount);
                      dispatch(
                        updateItemCount({ productId: product.id, newCount })
                      );
                    }
                  }}
                />
                <input
                  type="button"
                  value="+"
                  onClick={() => countCartIncrement(product.id)}
                />
              </div>
            </div>
            <div className="prices">
              <div className="price_desc">Цена за {product.unit}</div>
              <div className="price">{priceLess100000?.toLocaleString()} ₽</div>
            </div>
          </div>
        </div>
      </div>
      <div className="cartelement_tabs">
        <div className="cartelement_tab">
          <div
            className={`cartelement_tab_item ${switcher === 1 ? "active" : ""}`}
            onClick={() => setSwitcher(1)}
          >
            технические характеристики
          </div>
          <div
            className={`cartelement_tab_item ${switcher === 2 ? "active" : ""}`}
            onClick={() => setSwitcher(2)}
          >
            подробная информация
          </div>
          <div
            className={`cartelement_tab_item ${switcher === 3 ? "active" : ""}`}
            onClick={() => setSwitcher(3)}
          >
            инструкции
          </div>
          <div
            className={`cartelement_tab_item ${switcher === 4 ? "active" : ""}`}
            onClick={() => setSwitcher(4)}
          >
            сертификаты
          </div>
        </div>
        <div className="cartelement_tab_content">
          <div
            className={`cartelement_tab_content_item ${
              switcher === 1 ? "active" : ""
            }`}
          >
            {/* {characteristics.length > 1 && (
              <table>
                {characteristics.map(
                  (item, index) =>
                    index % 2 === 0 && (
                      <tr key={index}>
                        <td>{item[0]}</td>
                        <td>{item[1]}</td>
                        {characteristics[index + 1] && (
                          <>
                            <td>{characteristics[index + 1][0]}</td>
                            <td>{characteristics[index + 1][1]}</td>
                          </>
                        )}
                      </tr>
                    )
                )}
              </table>
            )} */}

            <div className="for_tables">
              <div className="for_table">
                <div className="for_table_1">
                  <h3 className="size_for_media">Код</h3>
                  <h3>С-000283647</h3>
                </div>
              </div>
              <div className="for_table">
                <div className="for_table_1">
                  <h3 className="size_for_media">Бренд </h3>.<h3>FUBAG</h3>
                </div>
              </div>
              <div className="for_table">
                <div className="for_table_1">
                  <h3 className="size_for_media">Артикул производителя </h3>
                  <h3>014091</h3>
                </div>
              </div>
              <div className="for_table">
                <div className="for_table_1">
                  <h3 className="size_for_media">Вес, кг</h3>
                  <h3>0.009</h3>
                </div>
              </div>
              <div className="for_table">
                <div className="for_table_1">
                  <h3 className="size_for_media">Страна происхождения</h3>
                  <h3>КИТАЙ</h3>
                </div>
              </div>
              <div className="for_table">
                <div className="for_table_1">
                  <h3 className="size_for_media">
                    Напряжение холостого хода, В
                  </h3>
                  <h3>67</h3>
                </div>
              </div>
              <div className="for_table">
                <div className="for_table_1">
                  <h3 className="size_for_media">
                    Диапазон регулирования сварочного тока в режиме MMA, А
                  </h3>
                  <h3>10-200</h3>
                </div>
              </div>
              <div className="for_table">
                <div className="for_table_1">
                  <h3 className="size_for_media">
                    Диапазон регулирования сварочного тока в режиме MMA, А
                  </h3>
                  <h3>10-200</h3>
                </div>
              </div>
            </div>
          </div>
          <p v-html="sanitizeHtml(info)"></p>
          <h2>Комплектация</h2>
          <p>
            Сварочный полуавтомат – 1 шт.; Горелка 3 м – 1 шт.; Кабель
            заземления, медь 3 м – 1шт.; Кабель с электрододержателем, медь 1,6
            м – 1 шт.; Газовый шланг 3 м – 1 шт.; Ролик 0,6-0,8 мм – 1 шт.
          </p>
          <p v-html="sanitizeHtml(equipment)"></p>
          <h2>Функции</h2>
          <p>
            Регулировка Hot Start (горячий старт). Увеличение сварочного
            (импульсного) тока в начальный момент для улучшения поджига дуги.
            Регулируется в пределах от 0 до 90%. Arc Force (стабильность горения
            дуги). Кратковременное увеличение силы тока в момент залипания
            электрода. Помогает капле оторваться от стержня электрода, делая тем
            самым процесс переноса капель через дуговой промежуток четким и
            равномерным. Anti Sticking (антиприлипание). Снижение сварочного
            тока для отделения залипшего электрода на изделии без повреждения
            обмазки. TIG Lift. Ручная сварка неплавящимся вольфрамовым
            электродом в среде защитного газа - аргона. Преимущественно
            используется для сварки цветных металлов и нержавеющих сталей
          </p>
          <p v-html="sanitizeHtml(functions)"></p>
        </div>
        <div
          className={`cartelement_tab_content_item ${
            switcher === 2 ? "active" : ""
          }`}
        >
          {/* {detailed_information} */}
        </div>
        <div
          className={`cartelement_tab_content_item ${
            switcher === 3 ? "active" : ""
          }`}
        >
          {/* {instructions} */}
        </div>
        <div
          className={`cartelement_tab_content_item ${
            switcher === 4 ? "active" : ""
          }`}
        >
          {/* { certificates } */}
        </div>
      </div>
    </>
  );
};

export default ProductComponent;

{
  /* <Swiper
            // width="70%"
            className="product_photo_slider_full"
            // style={{
            //   "--swiper-navigation-color": "#fff",
            //   "--swiper-pagination-color": "#fff",
            // }}
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
          >
            <SwiperSlide
              v-for="image in [trueImageUrl]"
              key="image"
              className="product_photo_slider_item swiper-slide"
            >
              <div
                className="product_photo_slider_item_photo"
                style={{ backgroundImage: `url(${trueImageUrl})` }}
              ></div>
            </SwiperSlide>
          </Swiper>
          <Swiper
            className="product_photo_slider_smile_preview"
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
          >
            <SwiperSlide
              key={trueImageUrl}
              className="product_photo_slider_smile_preview_item swiper-slide"
            >
              <div
                className="product_photo_slider_item_photo"
                style={{ backgroundImage: `url(${trueImageUrl})` }}
              ></div>
            </SwiperSlide>
          </Swiper> */
}
