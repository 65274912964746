import React from "react";
import "./partners.css";

export const Partners: React.FC = () => {
  return (
    <div className="partners partners_contacts">
      <div className="container">
        <div className="partners_desc">
          <a
            // className="BACKEND_PRICELIST"
          className="partners_details"
            href="BACKEND_URL_API_PRICELIST"
            download
          >
            <div className="icon">
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.7334 9.03797C14.3182 8.59494 13.3956 8.37342 12.1499 8.37342C11.4118 8.37342 10.6275 8.46203 9.70485 8.59494C8.7279 7.71618 7.91632 6.68199 7.30588 5.53797C7.76722 4.20886 8.09016 2.92405 8.09016 1.94937C8.09016 1.1962 7.81335 0 6.70614 0C6.3832 0 6.1064 0.177215 5.92186 0.443038C5.46052 1.24051 5.64506 3.01266 6.5216 4.87342C5.8813 6.65147 5.11046 8.38366 4.21491 10.057C1.76981 11.0316 0.155118 12.0949 0.0167162 12.9367C-0.0755517 13.3354 0.201252 14 1.17007 14C2.60022 14 4.16877 12.0063 5.36826 9.96835C6.75292 9.51111 8.17178 9.15565 9.61258 8.90506C11.3657 10.3671 12.8881 10.5886 13.6262 10.5886C15.1025 10.5886 15.2409 9.56962 14.7334 9.03797ZM6.15253 0.575949C6.5216 0.0443038 7.35202 0.221519 7.35202 1.28481C7.35202 1.99367 7.12135 3.14557 6.66001 4.47468C5.82959 2.61392 5.82959 1.1519 6.15253 0.575949ZM0.29352 12.981C0.431922 12.2722 1.81594 11.2975 3.98424 10.4557C2.78475 12.4051 1.58527 13.6456 0.847127 13.6456C0.385788 13.6456 0.247386 13.2468 0.29352 12.981ZM9.38191 8.63924C8.08199 8.87187 6.80203 9.19768 5.55279 9.61392C6.23562 8.43035 6.79205 7.1835 7.21361 5.89241C7.81998 6.88762 8.54789 7.80976 9.38191 8.63924ZM9.98165 8.81646C11.4579 8.59494 12.7036 8.63924 13.2572 8.72785C14.595 8.99367 14.1337 10.5443 12.6574 10.1899C11.5963 9.96835 10.7198 9.3924 9.98165 8.81646Z"
                  fill="black"
                ></path>
              </svg>
            </div>
            <span>Скачать реквизиты компании</span>
          </a>
          <p>
            «Будем Строить.ru» – динамично развивающаяся и успешно
            зарекомендовавшая себя компания, осуществляющая продажу
            стройматериалов оптом не только в Москве, но и в регионах России.
            Данная информация взята с сайта компании «Будем Строить»
          </p>
        </div>

        <div className="partners_wrap">
          <h2 className="title">
            наши <b>партнеры</b>
          </h2>
          <div className="partners_list row">
            <div className="partner_item">
              <img
                decoding="async"
                loading="lazy"
                src="/images/logos/image15.png"
                alt="Partner Logo"
              />
            </div>
            <div className="partner_item">
              <img
                decoding="async"
                loading="lazy"
                src="/images/logos/image11.png"
                alt="Partner Logo"
              />
            </div>
            <div className="partner_item">
              <img
                decoding="async"
                loading="lazy"
                src="/images/logos/image12.png"
                alt="Partner Logo"
              />
            </div>
            <div className="partner_item">
              <img
                decoding="async"
                loading="lazy"
                src="/images/logos/image18.png"
                alt="Partner Logo"
              />
            </div>
            <div className="partner_item">
              <img
                decoding="async"
                loading="lazy"
                src="/images/logos/image9.png"
                alt="Partner Logo"
              />
            </div>
            <div className="partner_item">
              <img
                decoding="async"
                loading="lazy"
                src="/images/logos/image16.png"
                alt="Partner Logo"
              />
            </div>
            <div className="partner_item">
              <img
                decoding="async"
                loading="lazy"
                src="/images/logos/image19.png"
                alt="Partner Logo"
              />
            </div>
            <div className="partner_item">
              <img
                decoding="async"
                loading="lazy"
                src="/images/logos/image20.png"
                alt="Partner Logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
