import React from "react";

export const Advantages: React.FC = () => {
  return (
    <div className="advantages">
      <div className="container row">
        <div className="advantages_item">
          <svg
            width="112"
            height="68"
            viewBox="0 0 112 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.8046 7.70278C17.1537 7.70278 19.1204 9.61481 19.1204 12.0185V13.1111H20.213V67.7407H31.1389V13.1111H31.7398L32.0676 12.6194C32.6139 11.8 33.488 11.363 34.4167 11.363C35.4 11.363 36.2741 11.8 36.7657 12.6194L37.0935 13.1111H37.6944C41.3 13.1111 44.25 10.1611 44.25 6.55556C44.25 2.95 41.3 0 37.6944 0H37.0935L36.7657 0.491667C36.2194 1.31111 35.3454 1.74815 34.4167 1.74815C33.4333 1.74815 32.5593 1.31111 32.0676 0.491667L31.7398 0H25.6759C18.6287 0 11.9639 2.18519 6.28241 6.33704L0 10.9259V13.6574L13.1111 8.03056C13.6574 7.81204 14.2037 7.70278 14.8046 7.70278ZM22.3981 65.5556V34.963H28.9537V65.5556H22.3981ZM28.9537 32.7778H22.3981V13.1111H28.9537V32.7778ZM42.0648 6.55556C42.0648 8.57685 40.6444 10.2704 38.787 10.762V2.34907C40.6444 2.84074 42.0648 4.53426 42.0648 6.55556ZM7.70278 7.97593C12.9472 4.20648 19.175 2.18519 25.6759 2.18519H30.5926C31.5213 3.27778 32.9417 3.93333 34.4167 3.93333C35.1815 3.93333 35.9463 3.76944 36.6019 3.44167V9.66945C35.9463 9.34167 35.1815 9.17778 34.4167 9.17778C32.9417 9.17778 31.5213 9.83333 30.5926 10.9259H21.1963C20.65 7.86667 18.0278 5.51759 14.8046 5.51759C13.9306 5.51759 13.0565 5.68148 12.237 6.06389L7.70278 7.97593Z"
              fill="#FF781F"
            ></path>
            <path
              d="M98.3518 1.69352V0H47V21.8519V22.9444V30.0463C47 33.6519 49.95 36.6019 53.5556 36.6019H63.3889C65.7926 36.6019 67.7593 38.5685 67.7593 40.9722V60.0926C67.7593 64.2991 71.2009 67.7407 75.4074 67.7407C79.6139 67.7407 83.0556 64.2991 83.0556 60.0926V40.9722C83.0556 38.5685 85.0222 36.6019 87.4259 36.6019H97.2593C100.865 36.6019 103.815 33.6519 103.815 30.0463V22.9444V21.8519V6.55556C103.815 4.15185 105.781 2.18519 108.185 2.18519H111.463V0H102.722C101.029 0 99.4991 0.655556 98.3518 1.69352ZM101.63 30.0463C101.63 32.45 99.663 34.4167 97.2593 34.4167H87.4259C83.8204 34.4167 80.8704 37.3667 80.8704 40.9722V60.0926C80.8704 63.0972 78.412 65.5556 75.4074 65.5556C72.4028 65.5556 69.9444 63.0972 69.9444 60.0926V40.9722C69.9444 37.3667 66.9944 34.4167 63.3889 34.4167H53.5556C51.1519 34.4167 49.1852 32.45 49.1852 30.0463V24.037H101.63V30.0463ZM101.63 6.55556V21.8519H49.1852V2.18519H90.7037V6.55556H92.8889V2.18519H96.1667V12.0185H98.3518V6.55556C98.3518 4.15185 100.319 2.18519 102.722 2.18519H103.323C102.285 3.33241 101.63 4.86204 101.63 6.55556Z"
              fill="#FF781F"
            ></path>
            <path
              d="M75.4074 61.7314C76.3126 61.7314 77.0463 60.9976 77.0463 60.0925C77.0463 59.1874 76.3126 58.4536 75.4074 58.4536C74.5023 58.4536 73.7686 59.1874 73.7686 60.0925C73.7686 60.9976 74.5023 61.7314 75.4074 61.7314Z"
              fill="#FF781F"
            ></path>
          </svg>
          <span>
            <b>Большой ассортимент</b>
            строительных материалов всегда в наличии
          </span>
        </div>
        <div className="advantages_item">
          <svg
            width="75"
            height="70"
            viewBox="0 0 75 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M51.2123 63.7684C50.9015 63.7679 50.6012 63.6562 50.3655 63.4535C50.1298 63.2509 49.9744 62.9707 49.9272 62.6635C49.88 62.3563 49.9442 62.0424 50.1082 61.7784C50.2722 61.5144 50.5252 61.3177 50.8215 61.2239L55.6414 59.7128C55.8063 59.6546 55.9812 59.6303 56.1556 59.6412C56.3301 59.6521 56.5006 59.698 56.6569 59.7761C56.8133 59.8543 56.9523 59.9631 57.0657 60.0961C57.1791 60.2291 57.2646 60.3836 57.3171 60.5503C57.3696 60.7171 57.3879 60.8927 57.3711 61.0666C57.3543 61.2406 57.3027 61.4095 57.2192 61.5631C57.1358 61.7167 57.0223 61.8519 56.8856 61.9608C56.7488 62.0696 56.5915 62.1498 56.4231 62.1965L51.6031 63.7077C51.477 63.749 51.345 63.7695 51.2123 63.7684Z"
              fill="#FF781F"
            ></path>
            <path
              d="M34.1826 69.0485C34.0132 69.0532 33.8445 69.0238 33.6867 68.9622C33.5288 68.9005 33.3849 68.8079 33.2635 68.6897C33.142 68.5715 33.0455 68.4301 32.9796 68.274C32.9137 68.1178 32.8798 67.9501 32.8799 67.7806V25.4085C32.8787 25.1311 32.9667 24.8607 33.1309 24.6371C33.295 24.4135 33.5267 24.2486 33.7918 24.1666L66.689 13.9102C66.8845 13.8496 67.0914 13.8354 67.2933 13.8685C67.4953 13.9017 67.6868 13.9813 67.8527 14.1012C68.0167 14.223 68.1501 14.3814 68.2421 14.5639C68.334 14.7463 68.3821 14.9477 68.3824 15.1521V39.9639C68.3824 40.3094 68.2452 40.6407 68.0009 40.885C67.7566 41.1293 67.4253 41.2666 67.0798 41.2666C66.7343 41.2666 66.4029 41.1293 66.1586 40.885C65.9143 40.6407 65.7771 40.3094 65.7771 39.9639V16.9237L35.52 26.3899V67.7111C35.5212 67.8871 35.4874 68.0615 35.4206 68.2243C35.3537 68.3871 35.2553 68.535 35.1309 68.6594C35.0064 68.7838 34.8585 68.8823 34.6958 68.9491C34.533 69.0159 34.3585 69.0497 34.1826 69.0485Z"
              fill="#FF781F"
            ></path>
            <path
              d="M34.182 69.0832C33.8712 69.0826 33.5709 68.9709 33.3352 68.7682C33.0996 68.5656 32.9441 68.2854 32.8969 67.9782C32.8498 67.671 32.914 67.3571 33.078 67.0931C33.242 66.8291 33.4949 66.6324 33.7912 66.5386L50.7869 61.2236C50.9529 61.1615 51.1299 61.134 51.3069 61.1427C51.4839 61.1513 51.6573 61.196 51.8164 61.274C51.9755 61.352 52.1171 61.4616 52.2324 61.5962C52.3477 61.7308 52.4343 61.8875 52.487 62.0567C52.5397 62.2259 52.5572 62.4041 52.5387 62.5804C52.5201 62.7566 52.4657 62.9272 52.3789 63.0817C52.2922 63.2363 52.1747 63.3714 52.0339 63.479C51.8931 63.5866 51.7318 63.6643 51.5599 63.7074L34.6076 69.0224C34.4704 69.0676 34.3264 69.0881 34.182 69.0832Z"
              fill="#FF781F"
            ></path>
            <path
              d="M39.393 61.2674C39.0822 61.2668 38.7818 61.1552 38.5462 60.9525C38.3105 60.7499 38.1551 60.4697 38.1079 60.1625C38.0607 59.8553 38.1249 59.5414 38.2889 59.2774C38.4529 59.0134 38.7059 58.8167 39.0022 58.7229L51.0129 54.9364C51.1778 54.8783 51.3527 54.8539 51.5271 54.8648C51.7016 54.8757 51.8721 54.9216 52.0284 54.9998C52.1848 55.0779 52.3238 55.1867 52.4372 55.3197C52.5506 55.4527 52.6361 55.6072 52.6886 55.774C52.7411 55.9407 52.7594 56.1163 52.7426 56.2903C52.7258 56.4643 52.6742 56.6331 52.5907 56.7867C52.5073 56.9403 52.3938 57.0756 52.257 57.1844C52.1203 57.2932 51.963 57.3734 51.7945 57.4202L39.8185 61.2067C39.6814 61.2518 39.5373 61.2724 39.393 61.2674Z"
              fill="#FF781F"
            ></path>
            <path
              d="M39.393 55.1886C39.0822 55.188 38.7818 55.0763 38.5462 54.8737C38.3105 54.6711 38.1551 54.3909 38.1079 54.0837C38.0607 53.7765 38.1249 53.4625 38.2889 53.1985C38.4529 52.9345 38.7059 52.7379 39.0022 52.644L48.147 49.7868C48.4764 49.6808 48.8344 49.7101 49.1422 49.8681C49.45 50.026 49.6825 50.2998 49.7884 50.6292C49.8944 50.9586 49.8651 51.3165 49.7071 51.6244C49.5492 51.9322 49.2754 52.1646 48.946 52.2706L39.8185 55.1278C39.6814 55.173 39.5373 55.1936 39.393 55.1886Z"
              fill="#FF781F"
            ></path>
            <path
              d="M34.1825 69.0138C34.0526 69.0155 33.9233 68.9949 33.8004 68.953L0.920568 58.6705C0.653912 58.5901 0.420304 58.4258 0.254455 58.2021C0.0886062 57.9783 -0.000631122 57.7071 3.3601e-06 57.4286V15.0826C3.3601e-06 14.7371 0.13725 14.4057 0.381551 14.1614C0.625852 13.9171 0.957196 13.7799 1.30269 13.7799C1.43828 13.7782 1.57323 13.7987 1.70218 13.8407L10.6212 16.6197C10.8855 16.7057 11.116 16.8728 11.2797 17.0974C11.4434 17.3219 11.5321 17.5924 11.5331 17.8703V35.7779C11.6358 35.9452 11.7007 36.133 11.7232 36.328C11.7457 36.523 11.7253 36.7206 11.6634 36.9069C11.5455 37.2319 11.3047 37.4977 10.9929 37.6471C10.6811 37.7965 10.3231 37.8176 9.99595 37.7059L9.7962 37.6364C9.54212 37.5452 9.32236 37.3779 9.16693 37.1572C9.0115 36.9365 8.92797 36.6731 8.92775 36.4032V18.8256L2.60538 16.8455V56.4733L32.9145 65.9394V26.3204L25.8192 24.1058V41.0408C25.8192 41.3863 25.682 41.7176 25.4377 41.9619C25.1934 42.2062 24.8621 42.3435 24.5166 42.3435C24.1711 42.3435 23.8397 42.2062 23.5954 41.9619C23.3511 41.7176 23.2139 41.3863 23.2139 41.0408V22.3342C23.2103 22.1285 23.2555 21.9249 23.3457 21.7401C23.436 21.5552 23.5687 21.3944 23.733 21.2707C23.8974 21.147 24.0887 21.064 24.2913 21.0285C24.4939 20.9929 24.702 21.0059 24.8987 21.0662L34.608 24.1058C34.8657 24.1941 35.0887 24.362 35.2448 24.5852C35.4009 24.8084 35.482 25.0754 35.4765 25.3477V67.7111C35.4783 67.9158 35.4311 68.1179 35.3389 68.3007C35.2468 68.4835 35.1123 68.6416 34.9467 68.7619C34.725 68.9244 34.4574 69.0126 34.1825 69.0138Z"
              fill="#FF781F"
            ></path>
            <path
              d="M24.5161 42.3695C24.1706 42.3695 23.8393 42.2322 23.595 41.9879C23.3507 41.7436 23.2134 41.4123 23.2134 41.0668V22.5079C23.1804 22.3104 23.1953 22.1078 23.2568 21.9173C23.3054 21.7521 23.3865 21.5982 23.4953 21.4647C23.6042 21.3312 23.7385 21.2207 23.8906 21.1398C24.0427 21.059 24.2094 21.0092 24.3809 20.9936C24.5524 20.978 24.7253 20.9968 24.8895 21.0488C25.1557 21.1341 25.3884 21.3005 25.5551 21.5248C25.7217 21.7491 25.8139 22.02 25.8188 22.2994V41.0407C25.8222 41.214 25.7911 41.3862 25.7272 41.5473C25.6633 41.7083 25.5679 41.855 25.4466 41.9788C25.3253 42.1025 25.1805 42.2008 25.0207 42.2679C24.8609 42.335 24.6894 42.3695 24.5161 42.3695Z"
              fill="#FF781F"
            ></path>
            <path
              d="M10.4302 37.7756C10.2828 37.7737 10.1365 37.7503 9.99594 37.7061L9.79619 37.6367C9.54211 37.5455 9.32235 37.3781 9.16692 37.1574C9.01149 36.9367 8.92796 36.6734 8.92773 36.4034V17.8706C8.92773 17.5251 9.06498 17.1937 9.30928 16.9494C9.55358 16.7051 9.88493 16.5679 10.2304 16.5679C10.5759 16.5679 10.9073 16.7051 11.1516 16.9494C11.3959 17.1937 11.5331 17.5251 11.5331 17.8706V35.7782C11.6358 35.9455 11.7007 36.1332 11.7232 36.3282C11.7457 36.5233 11.7253 36.7208 11.6634 36.9072C11.5711 37.1605 11.4035 37.3796 11.183 37.5349C10.9626 37.6901 10.6998 37.7741 10.4302 37.7756Z"
              fill="#FF781F"
            ></path>
            <path
              d="M34.1819 26.7026C34.0131 26.7015 33.8462 26.6671 33.6907 26.6015C33.5352 26.5358 33.3942 26.4402 33.2756 26.3201C33.1571 26.1999 33.0633 26.0576 32.9998 25.9012C32.9363 25.7448 32.9042 25.5774 32.9053 25.4086C32.9053 25.0631 33.0426 24.7318 33.2869 24.4875C33.5312 24.2432 33.8625 24.106 34.208 24.106C34.5535 24.106 34.8848 24.2432 35.1291 24.4875C35.3734 24.7318 35.5107 25.0631 35.5107 25.4086C35.5038 25.7557 35.3606 26.0861 35.1119 26.3283C34.8632 26.5705 34.5291 26.705 34.1819 26.7026Z"
              fill="#FF781F"
            ></path>
            <path
              d="M1.3203 16.377C1.02062 16.3754 0.730647 16.2706 0.499259 16.0802C0.26787 15.8897 0.109221 15.6253 0.0500555 15.3315C-0.0091096 15.0377 0.0348282 14.7325 0.174462 14.4674C0.314095 14.2022 0.540884 13.9933 0.816597 13.8759L33.818 0.136855C34.137 0.00326408 34.496 0.00186986 34.816 0.132978C35.1361 0.264086 35.3909 0.516958 35.5245 0.835964C35.6581 1.15497 35.6595 1.51398 35.5284 1.83401C35.3973 2.15405 35.1444 2.40889 34.8254 2.54248L1.82401 16.2815C1.66451 16.3478 1.493 16.3803 1.3203 16.377Z"
              fill="#FF781F"
            ></path>
            <path
              d="M11.273 19.199C10.9806 19.1926 10.6989 19.0879 10.4732 18.9019C10.2475 18.7159 10.091 18.4594 10.0288 18.1736C9.96663 17.8878 10.0024 17.5894 10.1305 17.3265C10.2585 17.0635 10.4712 16.8513 10.7345 16.7239L25.646 10.5232C25.9632 10.3998 26.3161 10.4055 26.6292 10.539C26.9423 10.6726 27.1907 10.9234 27.3212 11.2378C27.4518 11.5521 27.454 11.9051 27.3276 12.2212C27.2011 12.5372 26.956 12.7912 26.6447 12.9288L11.768 19.0948C11.6117 19.1625 11.4433 19.1979 11.273 19.199Z"
              fill="#FF781F"
            ></path>
            <path
              d="M24.4819 23.6195C24.2231 23.6199 23.9703 23.5421 23.7565 23.3965C23.5427 23.2508 23.3778 23.044 23.2834 22.8032C23.2122 22.6439 23.1737 22.4719 23.1702 22.2975C23.1667 22.123 23.1983 21.9496 23.2631 21.7876C23.3279 21.6256 23.4246 21.4783 23.5474 21.3544C23.6702 21.2305 23.8167 21.1325 23.9781 21.0663L37.1092 15.6037C37.4282 15.4701 37.7872 15.4687 38.1073 15.5998C38.4273 15.7309 38.6822 15.9838 38.8157 16.3028C38.9493 16.6218 38.9507 16.9808 38.8196 17.3008C38.6885 17.6208 38.4356 17.8757 38.1166 18.0093L24.9856 23.524C24.8257 23.5891 24.6544 23.6216 24.4819 23.6195Z"
              fill="#FF781F"
            ></path>
            <path
              d="M44.3867 6.87572C44.2136 6.87458 44.0424 6.83916 43.883 6.77151L33.8523 2.53343C33.6841 2.47507 33.5296 2.38283 33.3984 2.26241C33.2672 2.14199 33.1621 1.99594 33.0895 1.8333C33.017 1.67065 32.9786 1.49486 32.9766 1.31678C32.9747 1.13871 33.0093 0.962125 33.0783 0.797948C33.1473 0.633771 33.2493 0.485486 33.3778 0.362253C33.5064 0.23902 33.6589 0.143457 33.8258 0.0814672C33.9928 0.0194772 34.1706 -0.00762304 34.3485 0.00184077C34.5263 0.0113046 34.7003 0.0571308 34.8597 0.13649L44.8991 4.37456C45.0605 4.44079 45.207 4.53877 45.3298 4.6627C45.4527 4.78662 45.5494 4.93395 45.6142 5.09595C45.679 5.25796 45.7106 5.43133 45.7071 5.60577C45.7036 5.78022 45.6651 5.95219 45.5939 6.11148C45.4872 6.34127 45.3167 6.53552 45.1026 6.67103C44.8886 6.80655 44.6401 6.8776 44.3867 6.87572Z"
              fill="#FF781F"
            ></path>
            <path
              d="M67.0883 16.455C66.9151 16.4538 66.7439 16.4184 66.5846 16.3507L43.8831 6.79771C43.7191 6.73558 43.5694 6.64101 43.4429 6.51964C43.3164 6.39828 43.2157 6.25263 43.1468 6.09141C43.0779 5.93019 43.0422 5.75673 43.042 5.58141C43.0417 5.40609 43.0769 5.23253 43.1453 5.07111C43.2137 4.9097 43.314 4.76376 43.4402 4.64203C43.5664 4.52031 43.7158 4.4253 43.8796 4.36271C44.0434 4.30012 44.2181 4.27122 44.3933 4.27776C44.5685 4.2843 44.7405 4.32614 44.8992 4.40076L67.6007 13.9538C67.8771 14.0715 68.1042 14.2812 68.2438 14.5472C68.3833 14.8133 68.4266 15.1194 68.3663 15.4137C68.306 15.708 68.1458 15.9724 67.9129 16.1622C67.68 16.352 67.3887 16.4554 67.0883 16.455Z"
              fill="#FF781F"
            ></path>
            <path
              d="M24.5164 42.3703C24.2964 42.3697 24.08 42.3136 23.8872 42.2074C23.6945 42.1012 23.5316 41.9481 23.4135 41.7624L16.4658 30.8632C16.2918 30.5721 16.2381 30.2245 16.3163 29.8945C16.3945 29.5644 16.5984 29.2779 16.8846 29.0958C17.1707 28.9136 17.5167 28.8503 17.8488 28.9193C18.1809 28.9883 18.473 29.184 18.663 29.465L25.6107 40.3642C25.7339 40.5614 25.8025 40.7878 25.8094 41.0203C25.8162 41.2527 25.7612 41.4828 25.6498 41.687C25.5385 41.8912 25.3748 42.062 25.1756 42.1821C24.9765 42.3022 24.749 42.3671 24.5164 42.3703Z"
              fill="#FF781F"
            ></path>
            <path
              d="M10.4298 37.7757C10.2453 37.7765 10.0628 37.7374 9.89489 37.6609C9.72698 37.5844 9.57763 37.4725 9.45714 37.3328C9.34371 37.2053 9.25662 37.0566 9.20088 36.8954C9.14513 36.7341 9.12184 36.5634 9.13233 36.3931C9.14283 36.2227 9.1869 36.0562 9.26202 35.903C9.33714 35.7498 9.44182 35.6129 9.57004 35.5003L16.7001 29.1866C16.9592 28.9563 17.2992 28.8383 17.6453 28.8587C17.9914 28.8791 18.3152 29.0361 18.5455 29.2952C18.7759 29.5543 18.8938 29.8943 18.8735 30.2404C18.8531 30.5865 18.6961 30.9103 18.437 31.1407L11.307 37.4457C11.0679 37.6644 10.7538 37.7826 10.4298 37.7757Z"
              fill="#FF781F"
            ></path>
            <path
              d="M37.6566 18.1574C37.4712 18.1586 37.2876 18.1201 37.1182 18.0446L25.6719 12.8946C25.3786 12.74 25.1552 12.4794 25.0474 12.1658C24.9395 11.8522 24.9553 11.5093 25.0915 11.207C25.2278 10.9047 25.4741 10.6657 25.7805 10.5387C26.0868 10.4118 26.43 10.4064 26.7401 10.5237L38.1517 15.665C38.4217 15.7851 38.6429 15.9935 38.7788 16.2559C38.9148 16.5183 38.9575 16.8191 38.9 17.109C38.8424 17.3989 38.688 17.6606 38.462 17.8511C38.2361 18.0416 37.9521 18.1496 37.6566 18.1574Z"
              fill="#FF781F"
            ></path>
            <path
              d="M61.8695 63.7945C59.2655 63.8013 56.718 63.0359 54.5491 61.5949C52.3802 60.154 50.6873 58.1022 49.6845 55.6991C48.6816 53.296 48.4139 50.6495 48.9152 48.0943C49.4165 45.539 50.6642 43.1898 52.5006 41.3437C54.337 39.4975 56.6796 38.2374 59.2322 37.7227C61.7847 37.208 64.4326 37.4617 66.841 38.4518C69.2493 39.442 71.31 41.124 72.7624 43.2853C74.2148 45.4466 74.9937 47.99 75.0005 50.5939C75.0052 54.0843 73.6254 57.4341 71.1638 59.9087C68.7023 62.3833 65.3598 63.7807 61.8695 63.7945ZM61.8695 40.0682C59.7779 40.0614 57.7313 40.6757 55.9894 41.8335C54.2474 42.9912 52.8884 44.6402 52.0849 46.5713C51.2813 48.5024 51.0692 50.6286 51.4756 52.6803C51.8819 54.7321 52.8884 56.617 54.3674 58.096C55.8464 59.575 57.7313 60.5815 59.783 60.9878C61.8348 61.3942 63.961 61.1821 65.8921 60.3785C67.8232 59.5749 69.4721 58.216 70.6299 56.474C71.7876 54.7321 72.402 52.6855 72.3952 50.5939C72.3837 47.8059 71.2711 45.1352 69.2996 43.1638C67.3281 41.1923 64.6575 40.0797 61.8695 40.0682Z"
              fill="#FF781F"
            ></path>
            <path
              d="M60.4884 56.1696C60.143 56.1734 59.8097 56.0426 59.5591 55.8048L54.9737 51.202C54.7435 50.955 54.6183 50.6284 54.6242 50.2909C54.6302 49.9535 54.7669 49.6314 55.0056 49.3928C55.2442 49.1541 55.5662 49.0174 55.9037 49.0114C56.2412 49.0055 56.5678 49.1308 56.8148 49.3609L61.4089 53.9637C61.6508 54.209 61.7863 54.5397 61.7863 54.8842C61.7863 55.2288 61.6508 55.5594 61.4089 55.8048C61.1605 56.0405 60.8307 56.1711 60.4884 56.1696Z"
              fill="#FF781F"
            ></path>
            <path
              d="M60.4875 56.1696C60.2301 56.167 59.9791 56.0887 59.7659 55.9444C59.5527 55.8001 59.3867 55.5963 59.2885 55.3583C59.1904 55.1203 59.1644 54.8587 59.2139 54.6061C59.2634 54.3535 59.3862 54.121 59.567 53.9377L67.3049 46.1997C67.5519 45.9696 67.8785 45.8443 68.216 45.8503C68.5534 45.8563 68.8754 45.993 69.1141 46.2316C69.3528 46.4703 69.4895 46.7923 69.4955 47.1298C69.5014 47.4673 69.3761 47.7939 69.146 48.0409L61.4081 55.8049C61.1597 56.0405 60.8299 56.1712 60.4875 56.1696Z"
              fill="#FF781F"
            ></path>
          </svg>
          <span>
            <b>Доставка стройматериалов</b>в течение 12 часов или в любое
            удобное время
          </span>
        </div>
        <div className="advantages_item">
          <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M71.3059 0.0855C71.1664 0.027 71.0201 0.010125 70.875 0.010125V0H56.25H42.75H29.25C28.9508 0 28.665 0.118125 28.4546 0.329625L14.9557 13.8285C14.7521 14.0321 14.625 14.3134 14.625 14.625V42.75C14.625 43.3721 15.1279 43.875 15.75 43.875H57.375C57.6866 43.875 57.9679 43.7479 58.1715 43.5443L71.6704 30.0454C71.8819 29.835 72 29.5492 72 29.25V1.125C72 0.6705 71.7266 0.25875 71.3059 0.0855ZM56.9092 13.5H45.4658L56.7158 2.25H68.1592L56.9092 13.5ZM53.5342 2.25L41.9546 13.8296C41.7431 14.04 41.625 14.3258 41.625 14.625V24.8985L39.9994 23.814C39.6214 23.5609 39.1297 23.5609 38.7517 23.814L36 25.6478L33.2494 23.814C33.0604 23.688 32.8421 23.625 32.625 23.625C32.4079 23.625 32.1896 23.688 32.0006 23.814L30.375 24.8985V15.0907L43.2158 2.25H53.5342ZM29.7157 2.25H40.0342L28.7843 13.5H18.4657L29.7157 2.25ZM56.25 41.625H16.875V15.75H28.125V27C28.125 27.4151 28.3534 27.7965 28.719 27.9923C29.0835 28.188 29.529 28.1655 29.8733 27.936L32.625 26.1022L35.3756 27.936C35.7536 28.1891 36.2453 28.1891 36.6233 27.936L39.375 26.1022L42.1256 27.936C42.3135 28.0609 42.5317 28.125 42.75 28.125C42.9322 28.125 43.1145 28.0811 43.281 27.9923C43.6466 27.7965 43.875 27.4151 43.875 27V15.75H56.25V41.625ZM69.75 28.7843L58.5 40.0342V15.0907L69.75 3.84075V28.7843Z"
              fill="#FF781F"
            ></path>
            <path
              d="M21.375 66.375C21.9971 66.375 22.5 65.8721 22.5 65.25H25.6095L37.6369 71.2643C38.1566 71.5241 38.7202 71.6546 39.2816 71.6546C39.9217 71.6546 40.5607 71.487 41.1334 71.1529L65.6764 56.835C66.8014 56.1791 67.5 54.963 67.5 53.6603C67.5 52.5724 67.0219 51.5464 66.1894 50.8455C65.358 50.1457 64.2623 49.851 63.1935 50.0366L40.8825 53.9167L34.5454 47.5796C34.335 47.3681 34.0493 47.25 33.75 47.25H22.5C22.5 46.6279 21.9971 46.125 21.375 46.125H16.875H0V48.375H15.75V64.125H0V66.375H16.875H21.375ZM33.2843 49.5L42.1481 58.3639C42.5306 58.7464 42.75 59.2763 42.75 59.8185C42.75 60.9525 41.8275 61.875 40.6935 61.875C40.1524 61.875 39.6214 61.6556 39.2389 61.2731L33.4204 55.4546L31.8296 57.0454L37.6481 62.8639C38.4615 63.6773 39.5426 64.125 40.6935 64.125C43.0672 64.125 45 62.1923 45 59.8185C45 58.6688 44.5522 57.5865 43.7389 56.7731L42.8276 55.8619L63.5771 52.2529C63.9967 52.1775 64.4175 52.2945 64.7404 52.5668C65.0644 52.839 65.25 53.2372 65.25 53.6591C65.25 54.1642 64.9789 54.6368 64.5424 54.891L40.0005 69.2089C39.5843 69.4519 39.0769 69.4676 38.6437 69.2516L26.379 63.1192C26.2226 63.0405 26.0494 63 25.875 63H22.5V49.5H33.2843ZM20.25 64.125H18V48.375H20.25V64.125Z"
              fill="#FF781F"
            ></path>
            <path
              d="M10.125 10.125C10.125 10.7471 10.6279 11.25 11.25 11.25C11.8721 11.25 12.375 10.7471 12.375 10.125C12.375 8.26425 13.8892 6.75 15.75 6.75C16.3721 6.75 16.875 6.24713 16.875 5.625C16.875 5.00287 16.3721 4.5 15.75 4.5C13.8892 4.5 12.375 2.98575 12.375 1.125C12.375 0.502875 11.8721 0 11.25 0C10.6279 0 10.125 0.502875 10.125 1.125C10.125 2.98575 8.61075 4.5 6.75 4.5C6.12788 4.5 5.625 5.00287 5.625 5.625C5.625 6.24713 6.12788 6.75 6.75 6.75C8.61075 6.75 10.125 8.26425 10.125 10.125ZM11.25 4.49662C11.5706 4.92413 11.9509 5.30438 12.3784 5.625C11.9509 5.94562 11.5706 6.32587 11.25 6.75337C10.9294 6.32587 10.5491 5.94562 10.1216 5.625C10.5491 5.30438 10.9294 4.92413 11.25 4.49662Z"
              fill="#FF781F"
            ></path>
          </svg>
          <span>
            <b>Простой возврат товаров</b>
            которые не пригодились в течение 180 дней
          </span>
        </div>
        <div className="advantages_item">
          <svg
            width="65"
            height="65"
            viewBox="0 0 65 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M63.6458 31.1458C62.8983 31.1458 62.2917 31.7525 62.2917 32.5C62.2917 48.926 48.926 62.2917 32.5 62.2917C16.074 62.2917 2.70833 48.926 2.70833 32.5C2.70833 16.074 16.074 2.70833 32.5 2.70833C41.9737 2.70833 50.849 7.20688 56.4633 14.7983L30.5554 40.7062L20.9977 31.1485C20.4696 30.6204 19.611 30.6204 19.0829 31.1485C18.5548 31.6767 18.5548 32.5352 19.0829 33.0633L29.5994 43.5798C30.1275 44.1079 30.986 44.1079 31.5142 43.5798L59.1879 15.9033C59.6483 15.4429 59.716 14.7171 59.3477 14.1808C53.2783 5.30292 43.2412 0 32.5 0C14.579 0 0 14.579 0 32.5C0 50.421 14.579 65 32.5 65C50.421 65 65 50.421 65 32.5C65 31.7525 64.3933 31.1458 63.6458 31.1458Z"
              fill="#FF781F"
            ></path>
          </svg>
          <span>
            <b>Простой возврат товаров</b>
            качеством по стандарту ISO 9001
          </span>
        </div>
      </div>
    </div>
  );
};
